/* eslint-disable no-new */
/* eslint-disable no-prototype-builtins */
/* global isIframe: true */

import angular from 'angular';
import jQuery from 'jquery';
import moment from 'moment';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import Treemap from 'highcharts/modules/treemap';
import 'highcharts-regression';
import 'ng-redux';
import '@cgross/angular-notify/dist/angular-notify.min.css';
import '@cgross/angular-notify/dist/angular-notify.min.js';

import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '../golfleet-dashboard-block/golfleet-dashboard-block';
import '@power/power-components/components/power-single-checkbox/power-single-checkbox';
import '../power-loader/power-loader';
import '../golfleet-dashboard-error/golfleet-dashboard-error';
import '../golfleet-dashboard-no-data/golfleet-dashboard-no-data';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-toast/power-toast';
import '../golfleet-map-speed-limit/golfleet-map-speed-limit';
import '@power/power-components/helpers/format-number/format-number';

import { FilterCondition } from '@power/power-components/components/power-filter/power-filter';
import { ReportStateConfig } from '@power/power-components/components/power-state-config/power-state-config';
import '@power/power-components/helpers/is-iframe/is-iframe';

import template from './golfleet-dashboard-speed-limit.html';
import './golfleet-dashboard-speed-limit.scss';

Exporting(Highcharts);
OfflineExporting(Highcharts);
Treemap(Highcharts);

class GolfleetDashboardSpeedLimitController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$scope',
      '$rootScope',
      '$http',
      'urlApi',
      'filterServices',
      'commonServices',
      'dashboardServices',
      '_today',
      '_months',
      '$timeout',
      '$interval',
      'notify',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $state,
    $scope,
    $rootScope,
    $http,
    urlApi,
    filterServices,
    commonServices,
    dashboardServices,
    _today,
    _months,
    $timeout,
    $interval,
    notify,
  ) {
    Object.assign(this, {
      $: $element[0],
      $ngRedux,
      $state,
      $scope,
      $rootScope,
      $http,
      urlApi,
      filterServices,
      commonServices,
      dashboardServices,
      _today,
      _months,
      $timeout,
      $interval,
      notify,
    });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      return Object({
        /* State Storage */
        lastState:
          behavior.state.routeList.length > 1
            ? behavior.state.routeList[behavior.state.routeList.length - 2]
            : null,
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
        /* Session Storage */
        application: behavior.session.application,
      });
    })(this);

    this.legacyEquipaments = { status: 0, list: [], count: 0, highlight: true }; // 0. loading, 1. ok, 2. no data, 3. error
    this.msgLegacyEquipament = ' equipamentos incompatíveis não constam neste Dashboard';
    this.toastDashboard = {};
    this.dashboardConfig = {};
    this.filters = {};

    this.configDataMonthlyResume = 0;
    this.configDataInfoHighlight = 0;
    this.configDataTotalViolation = 0;
    this.configDataInfratorRanking = 0;
    this.configDataTopViolationDetail = 0;
    this.configDataInfratorRankingDetail = 0;

    this.notify.config({
      startTop: 75,
      maximumOpen: 5,
    });

    this.dataMonthlyResume = [];
    this.dataInfoHighlights = [];
    this.dataRanking = [];
    this.dataRankingDetail = {
      dashboard: {},
      map: {},
    };
    this.mapLayers = {
      heatMap: {
        layer: null,
      },
    };
    this.monthlyResumeChartSeries = [];
    this.monthlyResumeChartSeriesMobile = [];
    this.monthlyResumeChartTotal = [];
    this.totalViolationChartInfo = {};
    this.infoHighlightItemVisible = 0;
    this.hasSwipeableCards = false;
    this.rankingViewSelected = 0; // 0 - veículo, 1 - condutor, 2 - via
    this.rankingItemSelected = {};
    this.rankingItemDetailShow = 1; // 0 mapa, 1 dashboard
    this.rankingItemDetailTitle = '';
    this.rankingItemDetailChartSeries = [];
    this.rankingItemDetailChartTotal = [];
    this.hasLastMonth = false;
    this.monthlyResumeChart = null;
    this.totalViolationChart = null;
    this.totalViolationChartTitle = null;
    this.intervalInfoHighlight = null;
    this.cardAnimated = false;
    this.rankingDetailChart = null;
    this.realTimeNotifications = [];
    this.intervalRealTimeDefault = 10000;
    this.realTimeActive = false;
    this.map = null;
    this.isIframe = isIframe();
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      requestDataset: this.requestDataset.bind(this),
    });

    this.toastDashboard = {
      text: '',
    };

    this.$scope.$on('UPDATE_ROUTE', () => this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' }));

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    if (!this.stateConfig.filterConfig || this.stateConfig.filterConfig.length == 0)
      this._getStateConfig();
    else this._getDashboardData();
  }

  $onDestroy() {
    this.__appBehavior();
    this.realTimeActive = false;
    this._evtClickRealtimeControl();
  }
  /* */

  /* Public */
  requestDataset() {
    this._getDashboardData();
  }

  toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }
  /* */

  /* Private */
  _getStateConfig() {
    this.filterServices
      .getPowerFilters(this.stateConfig.screenName, 'SpeedLimitModule')
      .then(getFiltersResult => {
        if (getFiltersResult) {
          const { drilldownMap } = getFiltersResult;
          const filterConfigList = getFiltersResult.filters;
          this.stateConfig.filterConfig = filterConfigList.map(filterConfig =>
            Object.clone({}, filterConfig),
          );
          if (
            this.lastState &&
            drilldownMap.length > 0 &&
            this.lastState.stateConfig.filterConfig
          ) {
            const lastFilterConfigList = this.lastState.stateConfig.filterConfig;
            this.stateConfig.filterConfig = this.stateConfig.filterConfig.map(actualFilter => {
              const drilldown = drilldownMap.filter(item => item.toId == actualFilter.id)[0];
              if (drilldown) {
                const previousFilter = lastFilterConfigList.filter(
                  filter => filter.id == drilldown.fromId,
                )[0];
                return {
                  ...actualFilter,
                  condition: !previousFilter ? null : Object.clone(previousFilter.condition),
                };
              }
              return actualFilter;
            });
          }
          if (this.stateConfig.getDataFilters) {
            this.stateConfig.filterConfig = this.stateConfig.filterConfig.map(actualFilter => {
              const fixedFilters = this.stateConfig.getDataFilters.filter(
                condition => condition.id == actualFilter.id,
              )[0];
              return fixedFilters
                ? {
                    ...actualFilter,
                    condition: new FilterCondition(
                      actualFilter.id,
                      actualFilter.field || actualFilter.filters[fixedFilters.activeView].field,
                      fixedFilters.default,
                    ),
                  }
                : actualFilter;
            });
          }
        }
        this.commonServices
          .getToolbarOptions(
            this.stateConfig.screenName,
            this.stateConfig.toolbarName,
            getToolbarResult => {
              const { addConfig, viewsConfig, exportConfig, importConfig, actionConfig } =
                getToolbarResult;
              Object.assign(this.stateConfig.toolbarConfig, {
                addConfig,
                viewsConfig,
                exportConfig,
                actionConfig,
                importConfig,
              });
              this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
            },
          )
          .then(() =>
            document
              .querySelector('#app-component')
              .querySelector('power-filter-menu')
              .applyFilters(),
          );
      })
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  _getDashboardData() {
    this.__clearData();

    let isDriver = false;
    let finalDateObj = null;
    const filterConditionsLength = this.stateConfig.filterConditions.length;

    const filterEquipaments = this.stateConfig.filterConditions.filter(
      item => item.field == 'vehicles' || item.field == 'driver',
    )[0];
    this.filters = {
      filter: {
        conditions: this.stateConfig.filterConditions,
      },
    };

    for (let conditionIndex = 0; conditionIndex < filterConditionsLength; conditionIndex += 1) {
      if (this.stateConfig.filterConditions[conditionIndex].field == 'date') {
        finalDateObj = new Date(this.stateConfig.filterConditions[conditionIndex].value[1]);
      }
      if (this.stateConfig.filterConditions[conditionIndex].field == 'driver') {
        isDriver = true;
      }
    }

    if (
      filterEquipaments &&
      (filterEquipaments.value.length === 0 ||
        filterEquipaments.value.length == filterEquipaments.total)
    ) {
      this.commonServices.getLegacyEquipaments('LegacyEquipaments/GetLegacyEquipaments').then(
        success => {
          if (success.data && success.data.hasError) {
            this.legacyEquipaments.status = 3;
            return;
          }
          if (success.data.data && success.data.data.total > 0) {
            this.legacyEquipaments.list = success.data.data.equipaments;
            this.legacyEquipaments.count = success.data.data.total;
            this.legacyEquipaments.highlight = success.data.data.highlight;
            this.legacyEquipaments.status = 1;
          } else this.legacyEquipaments.status = 2;
        },
        () => {
          this.legacyEquipaments.status = 3;
        },
      );
    } else this.legacyEquipaments.status = 0;

    this.hasLastMonth =
      filterConditionsLength > 0 &&
      this._today.getFullYear() === finalDateObj.getFullYear() &&
      this._today.getMonth() === finalDateObj.getMonth();

    const moduleName = 'SpeedLimitModule';
    const msgErrorConfig =
      'Erro ao carregar as configurações do seu Dashboard. Por favor, atualize a página.';

    this.dashboardServices
      .getDashboardConfig(moduleName)
      .then(
        result => {
          // Após carregar as configurações do Dashboard, carrega as informações dos Block's
          if (result.status && result.status === 500) {
            this.toastDashboard.text = msgErrorConfig;
            this.$.querySelector('#toast-obj').toggle(true);
            return;
          }

          this.dashboardConfig = Object.clone({}, result);

          /* Status
           * 0: show loader
           * 1: ok, success
           * 2: not ok, error
           * 3: ok, success, but no data
           */

          this.dashboardServices.getGenericData(moduleName, 'GetDashboardData', this.filters).then(
            success => {
              if (success.data.hasError) {
                this.configDataMonthlyResume = 2;
                this.configDataTotalViolation = 2;
                return;
              }
              if (success.data.graphBottom.qtdInfracoes === 0) {
                this.configDataMonthlyResume = 3;
                this.configDataTotalViolation = 3;
                return;
              }

              this.dataMonthlyResume = success.data;
              this.dataMonthlyResume.hasLastMonth = this.hasLastMonth;
              const dataSeries = {
                hasLastMonth: this.hasLastMonth,
                data: [],
                categories: [],
              };

              this._monthlyResumeChartSetDataVVia(
                success.data.graphData,
                dataSeries.hasLastMonth,
                null,
                chartDataReturn => {
                  this._createChart(
                    'monthly-resume-chart',
                    'column',
                    chartDataReturn.data,
                    chartDataReturn.categories,
                    chartObj => {
                      this.monthlyResumeChart = chartObj;
                      const chartElement = this.$.querySelector('#monthly-resume-chart');

                      const chartTotalElement = this.$.querySelector('.chart-total');

                      const xAxisWidth = Math.floor(
                        chartElement.querySelector('.highcharts-xaxis').getBoundingClientRect()
                          .width,
                      );
                      chartTotalElement.style.paddingLeft = `${
                        chartElement.clientWidth - xAxisWidth - 14
                      }px`;
                      chartTotalElement.style.paddingRight = '14px';
                      this.monthlyResumeChartSeries = this.monthlyResumeChart.series.map(serie => ({
                        color: serie.color,
                        name: serie.name,
                        visible: serie.visible,
                        index: serie.index,
                      }));
                      this.monthlyResumeChartSeries.push(
                        this.monthlyResumeChartSeries.splice(0, 1)[0],
                      );
                      this.monthlyResumeChartSeriesMobile = this.monthlyResumeChartSeries.map(
                        serie => ({
                          id: serie.index,
                          color: serie.color,
                          description: serie.name,
                          value: serie.index,
                          selected: serie.visible,
                        }),
                      );
                      this.configDataMonthlyResume = 1;
                    },
                  );
                },
              );

              this._totalViolationChartSetData(
                success.data.graphTotalViolation,
                chartDataReturn => {
                  this.totalViolationChart = this._createChartWithNew(
                    'speedlimit-total-violation-chart',
                    'pie',
                    chartDataReturn.dataChart,
                  );
                  this.totalViolationChart.dataAll = chartDataReturn.fullData;
                  this.totalViolationChartTitle = this.totalViolationChart.renderer
                    .label('', null, null, null, null, null, true)
                    .add();
                  this._setChartTitlePosition(
                    this.totalViolationChart,
                    this.totalViolationChartTitle,
                  );

                  this.totalViolationChartInfo = {
                    desc: '',
                    value: chartDataReturn.fullData.y.formatNumber(0, ',', '.'),
                    fuel: chartDataReturn.fullData.name,
                    color: chartDataReturn.fullData.color,
                  };

                  this.totalViolationChart.customOptions = {
                    setChartScopeApply: this._setChartScopeApply.bind(this),
                    totalViolationChart: this.totalViolationChart,
                    totalViolationChartTitle: this.totalViolationChartTitle,
                    totalViolationChartInfo: this.totalViolationChartInfo,
                    setChartTitle: this._setChartTitle.bind(this),
                    setChartTitlePosition: this._setChartTitlePosition.bind(this),
                  };
                  this.configDataTotalViolation = 1;
                },
              );
            },
            () => {
              this.configDataMonthlyResume = 2;
              this.configDataTotalViolation = 2;
            },
          );

          this._getTopViolations(moduleName, this.filters, true, data => {
            this.dataInfoHighlights = data;

            this.$timeout(() => {
              if (window.innerWidth <= 768) {
                this.__SwipeableCards(this.$.querySelector('.block-info-highlights .block-body'));
                this.hasSwipeableCards = true;
                this.$timeout(() => {
                  this.__FirstAnimation();
                }, 500);
              } else this.hasSwipeableCards = false;

              this.infoHighlightItemVisible = 0;
              this._infoHighlightsIntervalControl('start');
            }, 100);
          });

          const filtersRanking = Object.clone({}, this.filters);
          filtersRanking.visualization = isDriver ? 1 : 0;

          this._getRanking(moduleName, filtersRanking, data => {
            if (data.data.length > 0) {
              [this.rankingItemSelected] = data.data;
              this.dataRanking = data;
              this.rankingViewSelected = data.reportType;

              const detailFilter = Object.clone({}, this.filters);
              if (this.rankingViewSelected === 0 || this.rankingViewSelected === 1) {
                detailFilter.idViolator = this.rankingItemSelected.id;
                this.rankingItemDetailTitle = this.rankingItemSelected.description;
              } else {
                detailFilter.streetId = this.rankingItemSelected.viaId.toString(); // string
                detailFilter.cityId = this.rankingItemSelected.municipioId.toString(); // string
                detailFilter.stateId = this.rankingItemSelected.estadoId; // int
                this.rankingItemDetailTitle = `${this.rankingItemSelected.rua} - ${this.rankingItemSelected.municipio}/${this.rankingItemSelected.estado}`;
              }
              detailFilter.visualization = this.rankingViewSelected;

              if (this.rankingItemDetailShow === 1) {
                this.dataRankingDetail.map = {};

                this._getDashboard(moduleName, detailFilter, dataDetail => {
                  this.dataRankingDetail.dashboard = dataDetail;

                  this.dataRankingDetail.dashboard.topViolations = [];
                  this._getTopViolations(moduleName, detailFilter, false, dataTopViolation => {
                    this.dataRankingDetail.dashboard.topViolations = dataTopViolation;
                  });

                  this._monthlyResumeChartSetDataVVia(
                    dataDetail.graphData,
                    this.hasLastMonth,
                    'line',
                    chartDataReturn => {
                      this.$timeout(() => {
                        this._createChart(
                          'ranking-detail-chart',
                          'line',
                          chartDataReturn.data,
                          chartDataReturn.categories,
                          chartObj => {
                            this.rankingDetailChart = chartObj;
                            this.rankingItemDetailChartSeries = this.rankingDetailChart.series.map(
                              serie => {
                                const values = dataDetail.graphTotalViolation.filter(
                                  totalViolation => {
                                    const violationDesc = totalViolation.descricaoInfracao;
                                    return violationDesc.replace('Infração ', '') == serie.name;
                                  },
                                )[0];

                                if (values) {
                                  return {
                                    color: serie.color,
                                    name: serie.name,
                                    visible: serie.visible,
                                    index: serie.index,
                                    idInfracao: values.idInfracao,
                                    value: values.qtdInfracoes,
                                    percent: values.qtdInfracoesPercent,
                                  };
                                }
                                return {
                                  color: serie.color,
                                  name: serie.name,
                                  visible: serie.visible,
                                  index: serie.index,
                                };
                              },
                            );
                          },
                        );
                      }, 100);
                    },
                  );
                });
              } else if (this.rankingItemDetailShow === 0) {
                this.dataRankingDetail.dashboard = {};
                this.rankingDetailChart = null;
                this._getRankingDetail(moduleName, detailFilter, dataDetail => {
                  this.dataRankingDetail.map = dataDetail;
                  this._rankingDetailMapSetData(dataDetail);
                });
              }
            }
          });
        },
        () => {
          this.toastDashboard.text = msgErrorConfig;
          this.$.querySelector('#toast-obj').toggle(true);
        },
      )
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  _goToLink(
    routeName,
    routeLink,
    stateFixedParams,
    fixedFilters,
    linkStateConfig,
    getDataMethod,
    backPagination,
    data,
  ) {
    let getDataFilters = [];
    const getDataFixedParams = {};
    if (backPagination === null) backPagination = true;
    // eslint-disable-next-line no-restricted-syntax
    for (const attr in stateFixedParams) {
      if (data && data[stateFixedParams[attr]])
        getDataFixedParams[attr] = data[stateFixedParams[attr]];
      else if (
        this.stateConfig.getDataFixedParams &&
        this.stateConfig.getDataFixedParams[stateFixedParams[attr]]
      )
        getDataFixedParams[attr] = this.stateConfig.getDataFixedParams[stateFixedParams[attr]];
      else getDataFixedParams[attr] = stateFixedParams[attr];
    }
    if (fixedFilters)
      getDataFilters = fixedFilters.map(filter => {
        const filterDefault = filter.condition.map((condition, index) => {
          const fieldData = data[condition.field];
          if (filter.type == 'DateTime') {
            let conditionDate = moment(fieldData).utcOffset(-3);

            conditionDate = conditionDate.subtract(condition.value, 'days');

            if (!filter.keepTime) {
              if (index == 0) conditionDate.hour(0).minute(0).second(0).millisecond(0);
              else if (index == 1) conditionDate.hour(23).minute(59).second(59).millisecond(0);
            }

            return conditionDate._d;
          }
          return condition.value ? fieldData + condition.value : fieldData;
        });
        return {
          id: filter.id,
          default: filterDefault,
        };
      });

    if (routeLink == 'rankingSpeedLimitReport') {
      getDataFilters = Object.assign([], getDataFilters, [
        { id: 4, default: [this.rankingViewSelected] },
      ]);
    }

    const stateConfig = new ReportStateConfig({
      viewMode: 'grid',
      getDataMethod,
      backPagination,
      getDataFilters,
      getDataFixedParams,
    });

    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const attr in linkStateConfig) {
      stateConfig[attr] = linkStateConfig[attr];
    }

    this.$ngRedux.dispatch({
      type: 'NEXT_ROUTE',
      data: {
        routeName: data && data[routeName] ? data[routeName] : routeName,
        routeLink,
        routeSubName: linkStateConfig?.routeSubName,
        routeTail: getDataFixedParams && getDataFixedParams.id ? getDataFixedParams.id : null,
        stateConfig,
      },
    });
    this.$state.go(routeLink, {
      tail: getDataFixedParams && getDataFixedParams.id ? getDataFixedParams.id : null,
    });
  }

  _setChartScopeApply(chartId, chartInfo) {
    switch (chartId) {
      case 'violation':
        this.totalViolationChartInfo = chartInfo;
        break;
      default:
        break;
    }
    this.$scope.$apply();
  }

  _setChartTitle(value, color) {
    const title = `<div class='flex-column d-center i-center chart-pie-title'><span style='color:${color}'>${
      value === 0 || value === 100 ? value : value.formatNumber(0, ',', '.')
    }</span></div>`;
    return title;
  }

  _setChartTitlePosition(chartObj, chartTitleObj) {
    const textBBox = chartTitleObj.getBBox();

    const x = chartObj.plotLeft + chartObj.plotWidth * 0.5 - textBBox.width * 0.5;

    const y = chartObj.plotTop + chartObj.plotHeight * 0.5 - textBBox.height * 0.5;
    chartTitleObj.attr({ x, y });
  }

  _getViolationColor(idViolation) {
    let color = '';
    switch (idViolation) {
      case 0:
        // média
        color = '#ff7300';
        break;
      case 1:
        // grave
        color = '#ff1d1d';
        break;
      case 2:
        // gravíssima
        color = '#6a020d';
        break;
      default:
        // todos
        color = '#555';
        break;
    }
    return color;
  }

  _monthlyResumeChartSetDataVVia(originalData, hasLastMonth, chartType, callback) {
    const chartDataReturn = { data: [], categories: [] };

    let violations = [];

    const tempData = originalData.map(item => ({
      ano: item.ano,
      mes: item.mes,
      mesNome: item.mesNome,
      idInfracao: item.idInfracao,
      infracao: item.descInfracao.replace('Infração ', ''),
      infracaoFull: item.descInfracao,
      color: this._getViolationColor(item.idInfracao),
      value: item.qtdInfracoes,
    }));
    if (tempData.length > 0) {
      const manipulatedData = tempData.reduce((acc, item) => {
        if (!(item.idInfracao in acc)) {
          acc[item.idInfracao] = item;
        }
        return acc;
      }, {});
      violations = Object.keys(manipulatedData)
        .sort((a, b) => b - a)
        .map(key => manipulatedData[key].infracao);
      violations.forEach(violation => {
        let serieIndex = 0;

        let serieVisible = true;

        const countItens = tempData.filter(item => item.infracao == violation).length;

        const valuesFromViolation = [];
        if (violation === 'Gravíssima') serieIndex = 1;
        else if (violation === 'Grave') serieIndex = 2;
        else if (violation === 'Média') serieIndex = 3;
        else if (violation === 'Todos') {
          serieIndex = 4;
          serieVisible = false;
        }
        tempData
          .filter(item => item.infracao == violation)
          .forEach((violationValue, indexViolation) => {
            let markerObj = {};
            if (hasLastMonth && indexViolation + 1 == countItens) {
              markerObj = {
                fillColor: countItens % 2 === 0 ? '#EBEBEB' : '#f7f7f7',
                lineColor: violationValue.color,
                lineWidth: 3,
                radius: 5,
                symbol: 'circle',
              };
            } else {
              markerObj = {
                radius: 5,
                lineColor: violationValue.color,
                symbol: 'circle',
              };
            }
            valuesFromViolation.push({
              marker: markerObj,
              color:
                hasLastMonth && indexViolation + 1 == countItens
                  ? 'transparent'
                  : violationValue.color,
              x: indexViolation,
              y: violationValue.value,
              tooltipOptions: {
                nameFull: violationValue.infracaoFull,
                titlePrefix: '',
                titleSufix: `/ ${violationValue.ano}`,
                hasLastMonth,
                color: violationValue.color,
              },
            });
          });
        if (violation !== 'Todos')
          serieVisible = valuesFromViolation.filter(item => item.y > 0).length > 0;

        chartDataReturn.data.push({
          name: violation,
          animation: false,
          borderWidth: 2,
          maxPointWidth: 30,
          data: valuesFromViolation,
          legendIndex: serieIndex,
          visible: serieVisible,
          color: valuesFromViolation[0].color,
          zIndex: serieIndex,
          zoneAxis: 'x',
          zones: hasLastMonth
            ? [
                {
                  value: valuesFromViolation.length - 2,
                },
                {
                  dashStyle: 'Dash',
                },
              ]
            : [],
        });
      });

      chartDataReturn.categories = tempData
        .filter(item => item.idInfracao == 9)
        .sort((a, b) => a.ano - b.ano)
        // .sort((a, b) => a.mes - b.mes)
        .map(item => item.mesNome);
      // eslint-disable-next-line no-unused-expressions
      chartDataReturn.data
        .filter(item => item.name == 'Todos')
        .map(item => {
          if (chartType == 'line') {
            this.rankingItemDetailChartTotal = item.data;
          } else {
            this.monthlyResumeChartTotal = item.data;
          }
          return item;
        })[0];
    }

    const chartBlockElement = this.$.querySelector('.block-main-chart');
    chartBlockElement.classList.remove('chart-categories-3', 'chart-categories-6');
    if (chartDataReturn.categories.length <= 3)
      chartBlockElement.classList.add('chart-categories-3');
    else if (chartDataReturn.categories.length > 3 && chartDataReturn.categories.length <= 6)
      chartBlockElement.classList.add('chart-categories-6');
    if (typeof callback === 'function') callback(chartDataReturn);
  }

  _totalViolationChartSetData(originalData, callback) {
    const chartDataReturn = { dataChart: [], fullData: {} };
    if (originalData.length > 0) {
      const violations = originalData
        .filter(item => item.idInfracao !== 9)
        .sort((a, b) => a.idInfracao - b.idInfracao);

      const violationsAll = originalData.filter(item => item.idInfracao == 9)[0];

      const valuesViolations = [];
      violations.forEach((violation, violationIndex) => {
        valuesViolations.push({
          chartId: 'violation',
          name: violation.descricaoInfracao.replace('Infração ', ''),
          nameFull: violation.descricaoInfracao,
          color: this._getViolationColor(violation.idInfracao),
          x: violationIndex,
          y: violation.qtdInfracoesPercent,
          valueForY: violation.qtdInfracoes,
        });
      });
      chartDataReturn.dataChart.push({
        name: 'violation',
        animation: false,
        borderColor: null,
        data: valuesViolations,
      });
      chartDataReturn.fullData = {
        y: violationsAll.qtdInfracoesTotal,
        name: violationsAll.descricaoInfracao,
        color: this._getViolationColor(violationsAll.idInfracao),
      };
    }
    if (typeof callback === 'function') callback(chartDataReturn);
  }

  _createTemplateRealTime(item) {
    return `
			<input type="hidden" class="real-time-lat" value="${item.latitude.replace(',', '.')}" />
			<input type="hidden" class="real-time-lng" value="${item.longitude.replace(',', '.')}" />
			<div class="flex-row i-center real-time-container ${
        item.idInfracao === 0 ? 'real-time-infracao-media' : ''
      } ${item.idInfracao === 1 ? 'real-time-infracao-grave' : ''} ${
      item.idInfracao === 2 ? 'real-time-infracao-gravissima' : ''
    }">
				<div>
					<div class="infracao-color">&nbsp;</div>
					<div class="infracao-name">
						<span>${item.descInfracao.split(' ')[1]}</span>
					</div>
				</div>
				<div class="infracao-info">
					<div class="infracao-description">${item.description}</div>
					${
            item.complemento && item.complemento !== ''
              ? `<div class="infracao-complement">${item.complemento}</div>`
              : ''
          }
					<div class="infracao-name-via">${item.nomeVia}</div>
					<div class="infracao-values">
						<span class="desc">VIA</span> <span style="margin-right:10px">${item.velocidadeVia} Km/h</span>
						<span class="desc">VEÍCULO</span> <span>${item.velocidadeVeiculo} Km/h</span>
					</div>
				</div>
			</div>
		`;
  }

  _createListRealTime(dateRealTime) {
    if (this.realTimeActive) {
      const filtersRealTime = Object.clone({}, this.filters);
      filtersRealTime.dateRealTime = dateRealTime
        ? new Date(dateRealTime)
        : // new Date(new Date(dateRealTime).setHours(new Date(dateRealTime).getHours() - new Date(dateRealTime).getTimezoneOffset() / 60)) :
          new Date();
      this.dashboardServices
        .getGenericData('SpeedLimitModule', 'GetViolationRealTime', filtersRealTime)
        .then(
          success => {
            if (success.hasError || success.status === 500) {
              this.toastDashboard.text =
                'Erro ao consultar informações em Tempo Real. Tente novamente!';
              this.$.querySelector('#toast-obj').toggle(true);
              this.realTimeActive = false;
              this._evtClickRealtimeControl();
              return;
            }
            if (success.data.data.length === 0) {
              this.realTimeNotifications.push(
                setTimeout(() => {
                  this._createListRealTime(success.data.dateRealTime);
                }, this.dashboardConfig.intervalRealTime || this.intervalRealTimeDefault),
              );
            } else {
              const listLength = success.data.data.length;
              success.data.data.forEach((item, index) => {
                this.realTimeNotifications.push(
                  setTimeout(() => {
                    const container = document.createElement('div');
                    container.innerHTML = this._createTemplateRealTime(item).trim();

                    this.notify({
                      messageTemplate: container,
                      duration: 0,
                      position: 'right',
                      container: this.$.querySelector('speed-limit-module'),
                    });

                    setTimeout(() => {
                      jQuery(container.parentElement).on('click', () => {
                        const lat = jQuery(container.parentElement).find('.real-time-lat').val();
                        const lng = jQuery(container.parentElement).find('.real-time-lng').val();

                        const link = document.createElement('a');
                        link.setAttribute(
                          'href',
                          `https://maps.google.com/maps?layer=c&q=${lat},${lng}&cbll=${lat},${lng}&cbp=11,0,0,0,0&z=17&ll=${lat},${lng}`,
                        );
                        link.setAttribute('target', '_blank');
                        link.click();
                      });
                    }, 200);
                  }, index * ((this.dashboardConfig.intervalRealTime || this.intervalRealTimeDefault) / listLength)),
                );
              });
              this.realTimeNotifications.push(
                setTimeout(() => {
                  this._createListRealTime(success.data.data[listLength - 1].dataHora);
                }, this.dashboardConfig.intervalRealTime || this.intervalRealTimeDefault),
              );
            }
          },
          () => {
            this.toastDashboard.text =
              'Erro ao consultar informações em Tempo Real. Tente novamente!';
            this.$.querySelector('#toast-obj').toggle(true);
            this.realTimeActive = false;
            this.evtClickRealtimeControl();
          },
        );
    }
  }

  _getTopViolations(moduleName, filters, isCard, callback) {
    this.dashboardServices.getGenericData(moduleName, 'GetTopViolations', filters).then(
      success => {
        if (success.data.hasError) {
          if (isCard) this.configDataInfoHighlight = 2;
          else this.configDataTopViolationDetail = 2;
          return;
        }
        if (success.data && success.data.length === 0) {
          if (isCard) this.configDataInfoHighlight = 3;
          else this.configDataTopViolationDetail = 3;
          return;
        }

        if (isCard) this.configDataInfoHighlight = 1;
        else this.configDataTopViolationDetail = 1;

        if (typeof callback === 'function') {
          callback(success.data);
        }
      },
      () => {
        if (isCard) this.configDataInfoHighlight = 2;
        else this.configDataTopViolationDetail = 2;
      },
    );
  }

  _getRanking(moduleName, filters, callback) {
    filters.filter.conditions.push({
      field: 'considerViolation',
      value: [1],
      incluedHistoricalData: false,
    });
    this.dashboardServices
      .getGenericData(moduleName, 'GetInfratorRankingData', {
        ...filters,
        isPaginated: true,
        page: 0,
        length: 10,
      })
      .then(
        success => {
          if (success.data.hasError) {
            this.configDataInfratorRanking = 2;
            this.configDataInfratorRankingDetail = 2;
            return;
          }
          if (success.data.total === 0) {
            this.configDataInfratorRanking = 3;
            this.configDataInfratorRankingDetail = 3;
            return;
          }

          success.data.data.forEach((item, index) => {
            item.index = index + 1;
          });

          this.configDataInfratorRanking = 1;
          if (typeof callback === 'function') {
            callback(success.data);
          }
        },
        () => {
          this.configDataInfratorRanking = 2;
          this.configDataInfratorRankingDetail = 2;
        },
      );
  }

  _getRankingDetail(moduleName, filters, callback) {
    const filtersDetail = Object.clone({}, filters);
    filtersDetail.length = 9999999;
    filtersDetail.isPaginated = true;
    filtersDetail.page = 0;
    this.dashboardServices
      .getGenericData(moduleName, 'GetInfratorRankingDetailData', filtersDetail)
      .then(
        success => {
          if (success.data.hasError) {
            this.configDataInfratorRankingDetail = 2;
            return;
          }
          if (success.data.total === 0) {
            this.configDataInfratorRankingDetail = 3;
            return;
          }
          this.configDataInfratorRankingDetail = 1;
          if (typeof callback === 'function') {
            callback(success.data);
          }
        },
        () => {
          this.configDataInfratorRankingDetail = 2;
        },
      );
  }

  _getDashboard(moduleName, filters, callback) {
    this.dashboardServices.getGenericData(moduleName, 'GetDashboardData', filters).then(
      success => {
        if (success.data.hasError) {
          this.configDataInfratorRankingDetail = 2;
          return;
        }
        if (success.data.graphBottom.qtdInfracoes === 0) {
          this.configDataInfratorRankingDetail = 3;
          return;
        }

        this.configDataInfratorRankingDetail = 1;
        if (typeof callback === 'function') {
          callback(success.data);
        }
      },
      () => {
        this.configDataInfratorRankingDetail = 2;
      },
    );
  }

  _createChart(element, chartType, dataset, categories, callback) {
    Highcharts.chart(
      {
        chart: {
          type: chartType || 'line',
          renderTo: this.$.querySelector(`#${element}`),
          spacing: chartType == 'line' ? [0, 0, 0, 0] : [14, 14, 10, 8],
          style: {
            fontFamily: 'Ubuntu',
            fontSize: '12px',
          },
        },
        title: {
          text: null,
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        exporting: { enabled: false },
        xAxis: {
          alternateGridColor: 'rgba(0, 0, 0, 0.05)',
          categories,
          labels: {
            y: 28,
            formatter() {
              return this.value.toString().substr(0, 3);
            },
          },
        },
        tooltip: {
          shared: true,
          borderRadius: 8,
          padding: 0,
          useHTML: true,
          formatter() {
            let tooltipHtml = "<div class='flex-column chart-tooltip'><span class='tooltip-title'>";

            if (this.points[0].point.tooltipOptions) {
              tooltipHtml += `${this.points[0].point.tooltipOptions.titlePrefix} ${this.x} ${this.points[0].point.tooltipOptions.titleSufix}`;
            } else {
              tooltipHtml += this.x;
            }
            tooltipHtml += '</span>';
            this.points.forEach(point => {
              tooltipHtml += "<span class='tooltip-text-content'>";
              if (point.point.tooltipOptions) {
                tooltipHtml += `<i class='material-icons md-13' style='color:${
                  point.point.tooltipOptions.color
                }'>fiber_manual_record</i>${point.y.formatNumber(0, ',', '.')}`;
              } else {
                tooltipHtml += point.y;
              }
              tooltipHtml += '</span>';
            });
            tooltipHtml += '</div>';
            return tooltipHtml;
          },
        },
        yAxis: {
          type: 'linear',
          title: { text: null },
          min: 0,
          labels: {
            enabled: chartType != 'line',
          },
        },
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver() {
                  if (this.series.xAxis.labelGroup.element.children)
                    this.series.xAxis.labelGroup.element.children[this.x].classList.add(
                      'main-chart-label-selected',
                    );
                  else if (this.series.xAxis.labelGroup.element.getElementsByTagName('text'))
                    this.series.xAxis.labelGroup.element
                      .getElementsByTagName('text')
                      [this.x].classList.add('main-chart-label-selected');
                },
                mouseOut() {
                  if (this.series.xAxis.labelGroup.element.children)
                    this.series.xAxis.labelGroup.element.children[this.x].classList.remove(
                      'main-chart-label-selected',
                    );
                  else if (this.series.xAxis.labelGroup.element.getElementsByTagName('text'))
                    this.series.xAxis.labelGroup.element
                      .getElementsByTagName('text')
                      [this.x].classList.remove('main-chart-label-selected');
                },
              },
            },
          },
        },
        series: dataset,
      },
      // eslint-disable-next-line func-names
      function () {
        if (typeof callback === 'function') {
          callback(this);
        }
      },
    );
  }

  _createChartWithNew(element, chartType, dataset) {
    return new Highcharts.Chart({
      chart: {
        type: chartType || 'line',
        renderTo: this.$.querySelector(`#${element}`),
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        margin: chartType == 'treemap' ? [0, 0, 0, 0] : undefined,
        spacing: chartType == 'treemap' ? [0, 0, 0, 0] : [10, 10, 15, 10],
        spacingBottom: 0,
        marginBottom: chartType == 'pie' ? 10 : 0,
        style: {
          fontFamily: 'Ubuntu',
          fontSize: '12px',
        },
      },
      title: {
        text: null,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      exporting: { enabled: false },
      yAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          enableMouseTracking: true,
          dataLabels: {
            enabled: false,
          },
          slicedOffset: 0,
          size: '100%',
          innerSize: '60%',
          point: {
            events: {
              mouseOver() {
                let chartInfo = null;
                switch (this.chartId) {
                  case 'violation':
                    this.series.chart.customOptions.totalViolationChartTitle.attr({
                      text: this.series.chart.customOptions.setChartTitle(this.y, this.color),
                    });
                    this.series.chart.customOptions.setChartTitlePosition(
                      this.series.chart.customOptions.totalViolationChart,
                      this.series.chart.customOptions.totalViolationChartTitle,
                    );
                    chartInfo = {
                      value: this.valueForY.formatNumber(0, ',', '.'),
                      violation: this.name,
                      color: this.color,
                    };
                    break;
                  default:
                    break;
                }
                this.series.chart.customOptions.setChartScopeApply(this.chartId, chartInfo);
              },
              mouseOut() {
                let chartInfo = null;
                switch (this.chartId) {
                  case 'violation':
                    this.series.chart.customOptions.totalViolationChartTitle.attr({
                      text: '',
                    });
                    this.series.chart.customOptions.setChartTitlePosition(
                      this.series.chart.customOptions.totalViolationChart,
                      this.series.chart.customOptions.totalViolationChartTitle,
                    );
                    chartInfo = {
                      value: this.series.chart.dataAll.y.formatNumber(0, ',', '.'),
                      violation: this.series.chart.dataAll.name,
                      color: this.series.chart.dataAll.color,
                    };
                    break;
                  default:
                    break;
                }
                this.series.chart.customOptions.setChartScopeApply(this.chartId, chartInfo);
              },
            },
          },
        },
      },
      tooltip: {
        enabled: false,
        useHTML: true,
      },
      series: dataset,
    });
  }

  _infoHighlightsIntervalControl(option) {
    if (this.hasSwipeableCards) {
      if (option == 'start') {
        this.intervalInfoHighlight = this.$interval(() => {
          this.__CardAnimation(
            this.infoHighlightItemVisible === 0
              ? this.dataInfoHighlights.length - 1
              : this.infoHighlightItemVisible - 1,
            this.infoHighlightItemVisible === this.dataInfoHighlights.length - 1
              ? 0
              : this.infoHighlightItemVisible + 1,
          );
        }, this.dashboardConfig.sliderInterval || 3000);
        this.$scope.$on('$destroy', () => {
          this.$interval.cancel(this.intervalInfoHighlight);
        });
      } else {
        this.$interval.cancel(this.intervalInfoHighlight);
        this.intervalInfoHighlight = null;
      }
    } else if (option == 'start') {
      this.intervalInfoHighlight = this.$interval(() => {
        this.infoHighlightItemVisible =
          this.infoHighlightItemVisible == this.dataInfoHighlights.length - 1
            ? 0
            : this.infoHighlightItemVisible + 1;
      }, this.dashboardConfig.sliderInterval || 3000);
      this.$scope.$on('$destroy', () => {
        this.$interval.cancel(this.intervalInfoHighlight);
      });
    } else {
      this.$interval.cancel(this.intervalInfoHighlight);
      this.intervalInfoHighlight = null;
    }
  }

  async _rankingDetailMapSetData(dataDetail) {
    const mapComponent = this.$.querySelector('#dashboard-report-map');
    await mapComponent.renderDataset({
      dataset: dataDetail.data,
      layerName: 'heatLayer',
      type: 'HeatLayer',
    });
    mapComponent.resizeMap();
    mapComponent.fitLayers([], 'heatLayer');
  }

  _evtClickPopupLegacyEquipament() {
    this.$.querySelector('#popup-legacy-equipament').toggle();
  }

  _evtClickMonthlyResumeChartSerie(evt, chartSerie) {
    evt.preventDefault();
    if (chartSerie.hasOwnProperty('visible') && chartSerie.visible) {
      this.monthlyResumeChart.series[chartSerie.index].hide();
    } else if (chartSerie.selected) {
      this.monthlyResumeChart.series[chartSerie.value].hide();
    } else if (chartSerie.hasOwnProperty('value')) {
      this.monthlyResumeChart.series[chartSerie.value].show();
    } else {
      this.monthlyResumeChart.series[chartSerie.index].show();
    }
    if (chartSerie.hasOwnProperty('value')) {
      chartSerie.selected = !chartSerie.selected;

      // eslint-disable-next-line no-unused-expressions
      this.monthlyResumeChartSeries
        .filter(item => item.index == chartSerie.value)
        .map(item => {
          item.visible = true;
          return item;
        })[0];
    } else {
      chartSerie.visible = !chartSerie.visible;

      // eslint-disable-next-line no-unused-expressions
      this.monthlyResumeChartSeriesMobile
        .filter(item => item.value == chartSerie.index)
        .map(item => {
          item.visible = true;
          return item;
        })[0];
    }
  }

  _evtClickRealtimeControl() {
    if (!this.realTimeActive) {
      this.notify.closeAll();
      this.realTimeNotifications.forEach(item => {
        clearTimeout(item);
      });
    } else {
      this._createListRealTime();
    }
  }

  _evtClickRankingView(view) {
    const filtersRanking = Object.clone({}, this.filters);
    this.configDataInfratorRanking = 0;
    this.configDataInfratorRankingDetail = 0;
    this.dataRankingDetail.dashboard = {};
    this.dataRankingDetail.map = {};
    const moduleName = 'SpeedLimitModule';

    if (view == 'veiculoCondutor') {
      filtersRanking.visualization = this.dataRanking.reportType;
      this.rankingViewSelected = this.dataRanking.reportType;
      this._getRanking(moduleName, filtersRanking, data => {
        if (data.data.length > 0) {
          [this.rankingItemSelected] = data.data;
          this.dataRanking = data;
          const detailFilter = Object.clone({}, this.filters);
          detailFilter.idViolator = this.rankingItemSelected.id;
          detailFilter.visualization = this.rankingViewSelected;
          this.rankingItemDetailTitle = this.rankingItemSelected.description;
          if (this.rankingItemDetailShow === 1) {
            this._getDashboard(moduleName, detailFilter, dataDetail => {
              this.dataRankingDetail.dashboard = dataDetail;

              this.dataRankingDetail.dashboard.topViolations = [];
              this._getTopViolations(moduleName, detailFilter, false, dataTopViolation => {
                this.dataRankingDetail.dashboard.topViolations = dataTopViolation;
              });
            });
          } else if (this.rankingItemDetailShow === 0) {
            this._getRankingDetail(moduleName, detailFilter, dataDetail => {
              this.dataRankingDetail.map = dataDetail;
              this._rankingDetailMapSetData(dataDetail);
            });
          }
        }
      });
    } else if (view == 'via') {
      filtersRanking.visualization = 2;
      this.rankingViewSelected = 2;
      this._getRanking(moduleName, filtersRanking, data => {
        if (data.data.length > 0) {
          [this.rankingItemSelected] = data.data;
          this.dataRanking = data;
          const detailFilter = Object.clone({}, this.filters);
          this._evtClickRankingList(this.rankingItemSelected);
          detailFilter.streetId = this.rankingItemSelected.viaId.toString(); // string
          detailFilter.cityId = this.rankingItemSelected.municipioId.toString(); // string
          detailFilter.stateId = this.rankingItemSelected.estadoId; // int
          detailFilter.visualization = this.rankingViewSelected;
          this.rankingItemDetailTitle = `${this.rankingItemSelected.rua} - ${this.rankingItemSelected.municipio}/${this.rankingItemSelected.estado}`;
          if (this.rankingItemDetailShow === 1) {
            this._getDashboard(moduleName, detailFilter, dataDetail => {
              this.dataRankingDetail.dashboard = dataDetail;

              this.dataRankingDetail.dashboard.topViolations = [];
              this._getTopViolations(moduleName, detailFilter, false, dataTopViolation => {
                this.dataRankingDetail.dashboard.topViolations = dataTopViolation;
              });
            });
          } else if (this.rankingItemDetailShow === 0) {
            this._getRankingDetail(moduleName, detailFilter, dataDetail => {
              this.dataRankingDetail.map = dataDetail;
              this._rankingDetailMapSetData(dataDetail);
            });
          }
        }
      });
    }
  }

  _evtClickRankingDetailChartSerie(detailSerie) {
    if (detailSerie.hasOwnProperty('visible') && detailSerie.visible) {
      this.rankingDetailChart.series[detailSerie.index].hide();
    } else {
      this.rankingDetailChart.series[detailSerie.index].show();
    }

    detailSerie.visible = !detailSerie.visible;
  }

  _evtClickRankingList(item) {
    if (item.index == this.rankingItemSelected.index) {
      return;
    }
    this.rankingItemSelected = item;
    this.dataRankingDetail.dashboard = {};
    this.dataRankingDetail.map = {};
    const detailFilter = Object.clone({}, this.filters);
    if (this.rankingViewSelected != 2) {
      detailFilter.idViolator = this.rankingItemSelected.id;
      detailFilter.visualization = this.rankingViewSelected;
      this.rankingItemDetailTitle = this.rankingItemSelected.description;
    } else {
      detailFilter.streetId = this.rankingItemSelected.viaId.toString(); // string
      detailFilter.cityId = this.rankingItemSelected.municipioId.toString(); // string
      detailFilter.stateId = this.rankingItemSelected.estadoId; // int
      detailFilter.visualization = this.rankingViewSelected;
      this.rankingItemDetailTitle = `${this.rankingItemSelected.rua} - ${this.rankingItemSelected.municipio}/${this.rankingItemSelected.estado}`;
    }
    this.configDataInfratorRankingDetail = 0;
    const moduleName = 'SpeedLimitModule';

    if (this.rankingItemDetailShow === 1) {
      this._getDashboard(moduleName, detailFilter, dataDetail => {
        this.dataRankingDetail.dashboard = dataDetail;

        this.dataRankingDetail.dashboard.topViolations = [];
        this._getTopViolations(moduleName, detailFilter, false, dataTopViolation => {
          this.dataRankingDetail.dashboard.topViolations = dataTopViolation;
        });

        if (this.rankingDetailChart) {
          this._monthlyResumeChartSetDataVVia(
            dataDetail.graphData,
            this.hasLastMonth,
            'line',
            chartDataReturn => {
              chartDataReturn.data.forEach((data, indexData) => {
                this.rankingDetailChart.series[indexData].setData(data.data, false);
              });
              this.rankingDetailChart.redraw();
              this.rankingItemDetailChartSeries = this.rankingDetailChart.series.map(serie => {
                const values = dataDetail.graphTotalViolation.filter(totalViolation => {
                  const violationDesc = totalViolation.descricaoInfracao;
                  return violationDesc.replace('Infração ', '') == serie.name;
                })[0];

                if (values) {
                  return {
                    color: serie.color,
                    name: serie.name,
                    visible: serie.visible,
                    index: serie.index,
                    idInfracao: values.idInfracao,
                    value: values.qtdInfracoes,
                    percent: values.qtdInfracoesPercent,
                  };
                }
                return {
                  color: serie.color,
                  name: serie.name,
                  visible: serie.visible,
                  index: serie.index,
                };
              });
            },
          );
        }
      });
    } else if (this.rankingItemDetailShow === 0) {
      this._getRankingDetail(moduleName, detailFilter, dataDetail => {
        this.dataRankingDetail.map = dataDetail;
        this._rankingDetailMapSetData(dataDetail);
      });
    }
  }

  _evtClickRankingDetailView(view) {
    if (this.rankingItemDetailShow == view) {
      return;
    }

    const detailFilter = Object.clone({}, this.filters);
    if (this.rankingViewSelected != 2) {
      detailFilter.idViolator = this.rankingItemSelected.id;
      detailFilter.visualization = this.rankingViewSelected;
    } else {
      detailFilter.streetId = this.rankingItemSelected.viaId.toString(); // string
      detailFilter.cityId = this.rankingItemSelected.municipioId.toString(); // string
      detailFilter.stateId = this.rankingItemSelected.estadoId; // int
      detailFilter.visualization = this.rankingViewSelected;
    }
    const moduleName = 'SpeedLimitModule';

    if (view == 1) {
      this.rankingItemDetailShow = 1;
      if (angular.equals(this.dataRankingDetail.dashboard, {})) {
        this.configDataInfratorRankingDetail = 0;
        this._getDashboard(moduleName, detailFilter, dataDetail => {
          this.dataRankingDetail.dashboard = dataDetail;

          this.dataRankingDetail.dashboard.topViolations = [];
          this._getTopViolations(moduleName, detailFilter, false, dataTopViolation => {
            this.dataRankingDetail.dashboard.topViolations = dataTopViolation;
          });

          if (this.rankingDetailChart) {
            this._monthlyResumeChartSetDataVVia(
              dataDetail.graphData,
              this.hasLastMonth,
              'line',
              chartDataReturn => {
                chartDataReturn.data.forEach((data, indexData) => {
                  this.rankingDetailChart.series[indexData].setData(data.data, false);
                });
                this.rankingDetailChart.redraw();
                this.rankingItemDetailChartSeries = this.rankingDetailChart.series.map(serie => {
                  const values = dataDetail.graphTotalViolation.filter(totalViolation => {
                    const violationDesc = totalViolation.descricaoInfracao;
                    return violationDesc.replace('Infração ', '') == serie.name;
                  })[0];
                  if (values) {
                    return {
                      color: serie.color,
                      name: serie.name,
                      visible: serie.visible,
                      index: serie.index,
                      idInfracao: values.idInfracao,
                      value: values.qtdInfracoes,
                      percent: values.qtdInfracoesPercent,
                    };
                  }
                  return {
                    color: serie.color,
                    name: serie.name,
                    visible: serie.visible,
                    index: serie.index,
                  };
                });
              },
            );
          } else {
            this._monthlyResumeChartSetDataVVia(
              dataDetail.graphData,
              this.hasLastMonth,
              'line',
              chartDataReturn => {
                this.$timeout(() => {
                  this._createChart(
                    'ranking-detail-chart',
                    'line',
                    chartDataReturn.data,
                    chartDataReturn.categories,
                    chartObj => {
                      this.rankingDetailChart = chartObj;
                      this.rankingItemDetailChartSeries = this.rankingDetailChart.series.map(
                        serie => {
                          const values = dataDetail.graphTotalViolation.filter(totalViolation => {
                            const violationDesc = totalViolation.descricaoInfracao;
                            return violationDesc.replace('Infração ', '') == serie.name;
                          })[0];
                          if (values) {
                            return {
                              color: serie.color,
                              name: serie.name,
                              visible: serie.visible,
                              index: serie.index,
                              idInfracao: values.idInfracao,
                              value: values.qtdInfracoes,
                              percent: values.qtdInfracoesPercent,
                            };
                          }
                          return {
                            color: serie.color,
                            name: serie.name,
                            visible: serie.visible,
                            index: serie.index,
                          };
                        },
                      );
                    },
                  );
                }, 100);
              },
            );
          }
        });
      }
    } else {
      this.rankingItemDetailShow = 0;
      if (angular.equals(this.dataRankingDetail.map, {})) {
        this.configDataInfratorRankingDetail = 0;
        this._getRankingDetail(moduleName, detailFilter, dataDetail => {
          this.dataRankingDetail.map = dataDetail;
          this._rankingDetailMapSetData(dataDetail);
        });
      }
    }
  }

  _evtClickGoToStreetView(item) {
    if (isIframe()) {
      return;
    }
    const lat = item.latitude.replace(',', '.');

    const lng = item.longitude.replace(',', '.');
    window.open(
      `https://maps.google.com/maps?layer=c&q=${lat},${lng}&cbll=${lat},${lng}&cbp=11,0,0,0,0&z=17&ll=${lat},${lng}`,
      '_blank',
    );
  }
  /* */

  // #region Protected
  __clearData() {
    this.configDataMonthlyResume = 0;
    this.configDataInfoHighlight = 0;
    this.configDataTotalViolation = 0;
    this.configDataInfratorRanking = 0;
    this.configDataInfratorRankingDetail = 0;
    this._infoHighlightsIntervalControl('stop');
    this.realTimeActive = false;
    this._evtClickRealtimeControl();
  }

  __SwipeableCards(cardsContainer) {
    this.targetBCR = null;
    this.target = null;
    this.startX = 0;
    this.currentX = 0;
    this.screenX = 0;
    this.targetX = 0;
    this.isScroll = null;
    this.draggingCard = false;

    this.onStart = evt => {
      const cardTarget = evt.target.classList.contains('card')
        ? evt.target
        : evt.target.closest('.card');
      if (!cardTarget || this.cardAnimated) return;
      this.isScroll = null;
      this.target = cardTarget;
      this.targetBCR = cardTarget.getBoundingClientRect();
      this.startY = evt.pageY || evt.touches[0].pageY;
      this.startX = evt.pageX || evt.touches[0].pageX;
      this.currentX = this.startX;
      this.draggingCard = true;
      cardTarget.style.willChange = 'transform';

      if (typeof this._lessPriceSuppliesIntervalControl !== 'undefined') {
        this._lessPriceSuppliesIntervalControl('stop');
      }
    };

    this.onMove = evt => {
      if (this.isScroll === null) {
        let diffY = (evt.pageY || evt.touches[0].pageY) - this.startY;

        let diffX = (evt.pageX || evt.touches[0].pageX) - this.startX;
        diffY = diffY < 0 ? diffY * -1 : diffY;
        diffX = diffX < 0 ? diffX * -1 : diffX;
        if (diffY > diffX) this.isScroll = true;
        else this.isScroll = false;
      }
      if (this.isScroll === true) this.target = null;
      else if (this.isScroll === false) evt.preventDefault();
      if (!this.target || !this.draggingCard) return;
      this.currentX = evt.pageX || evt.touches[0].pageX;
      if (this.currentX - this.startX < 0) {
        if (this.target.nextElementSibling)
          Object.assign(this.target.nextElementSibling.style, {
            zIndex: 2,
            opacity: 1,
            transform: 'translateX(0)',
          });
        if (this.target.previousElementSibling)
          Object.assign(this.target.previousElementSibling.style, {
            zIndex: 1,
            opacity: 0,
            pointerEvents: 'none',
          });
      } else {
        if (this.target.previousElementSibling)
          Object.assign(this.target.previousElementSibling.style, {
            zIndex: 2,
            opacity: 1,
            transform: 'translateX(0)',
          });
        if (this.target.nextElementSibling)
          Object.assign(this.target.nextElementSibling.style, {
            zIndex: 1,
            opacity: 0,
            pointerEvents: 'none',
          });
      }
    };

    this.onEnd = () => {
      if (!this.target) return;
      const threshold = this.targetBCR.width * 0.35;
      const screenX = this.currentX - this.startX;
      this.targetX = 0;
      if (
        Math.abs(screenX) > threshold &&
        ((screenX > 0 && this.target.previousElementSibling) ||
          (screenX < 0 && this.target.nextElementSibling))
      )
        this.targetX = screenX > 0 ? this.targetBCR.width : -this.targetBCR.width;
      this.draggingCard = false;

      if (typeof this._lessPriceSuppliesIntervalControl !== 'undefined') {
        this._lessPriceSuppliesIntervalControl('start');
      }
    };

    this.update = () => {
      requestAnimationFrame(this.update.bind(this));
      if (!this.target) return;
      if (this.draggingCard) this.screenX = this.currentX - this.startX;
      else this.screenX += (this.targetX - this.screenX) / 4;
      const normalizedDragDistance = Math.abs(this.screenX) / this.targetBCR.width;
      // eslint-disable-next-line no-restricted-properties
      const opacity = 1 - Math.pow(normalizedDragDistance, 3);
      Object.assign(this.target.style, { opacity, transform: `translateX(${this.screenX}px)` });
      if (this.draggingCard) return;
      const isNearlyAtStart = Math.abs(this.screenX) < 0.1;
      const isNearlyInvisible = opacity < 0.01;
      if (isNearlyInvisible) {
        if (this.targetX < 0) {
          this.lessPriceSuppliesItemVisible += 1;
          if (this.$scope) {
            this.$scope.$apply();
          }
          Object.assign(this.target.style, {
            zIndex: 1,
            opacity: 0,
            transform: 'translateX(0)',
            pointerEvents: 'none',
          });
          if (this.target.nextElementSibling)
            Object.assign(this.target.nextElementSibling.style, {
              zIndex: 3,
              pointerEvents: 'all',
            });
        } else if (this.targetX > 0) {
          this.lessPriceSuppliesItemVisible -= 1;
          if (this.$scope) {
            this.$scope.$apply();
          }
          Object.assign(this.target.style, {
            zIndex: 1,
            opacity: 0,
            transform: 'translateX(0)',
            pointerEvents: 'none',
          });
          if (this.target.previousElementSibling)
            Object.assign(this.target.previousElementSibling.style, {
              zIndex: 3,
              pointerEvents: 'all',
            });
        }
        this.target = null;
      } else if (isNearlyAtStart) this.target = null;
    };

    // Mobile
    cardsContainer.addEventListener('touchstart', this.onStart.bind(this));
    cardsContainer.addEventListener('touchmove', this.onMove.bind(this));
    cardsContainer.addEventListener('touchend', this.onEnd.bind(this));

    // Desktop
    cardsContainer.addEventListener('mousedown', this.onStart.bind(this));
    cardsContainer.addEventListener('mousemove', this.onMove.bind(this));
    cardsContainer.addEventListener('mouseup', this.onEnd.bind(this));

    requestAnimationFrame(this.update.bind(this));
  }

  __FirstAnimation() {
    if (!this.$) return;

    [this.cardElement] = this.$.querySelectorAll('.card');

    if (!this.cardElement) return;

    this.translateXPosition = 0;
    this.rightCardAnimation = () => {
      const lastXPosition = this.translateXPosition;
      Object.assign(this.cardElement.style, {
        transform: `translateX(${this.translateXPosition}%)`,
        willChange: 'transform',
      });
      this.translateXPosition += 1;
      if (lastXPosition == 10) {
        requestAnimationFrame(this.leftCardAnimation.bind(this));
      } else requestAnimationFrame(this.rightCardAnimation.bind(this));
    };
    this.leftCardAnimation = () => {
      const lastXPosition = this.translateXPosition;
      Object.assign(this.cardElement.style, {
        transform: `translateX(${this.translateXPosition}%)`,
      });
      this.translateXPosition -= 1;
      if (lastXPosition === 0) {
        if (this.cardElement.nextElementSibling)
          Object.assign(this.cardElement.nextElementSibling.style, { zIndex: 2, opacity: 1 });
        requestAnimationFrame(this.leftCardAnimation.bind(this));
      } else if (lastXPosition == -10) requestAnimationFrame(this.centerCardAnimation.bind(this));
      else requestAnimationFrame(this.leftCardAnimation.bind(this));
    };
    this.centerCardAnimation = () => {
      const lastXPosition = this.translateXPosition;
      Object.assign(this.cardElement.style, {
        transform: `translateX(${this.translateXPosition}%)`,
      });
      this.translateXPosition += 1;
      if (lastXPosition === 0) {
        Object.assign(this.cardElement.style, { willChange: null });
        if (this.cardElement.nextElementSibling)
          Object.assign(this.cardElement.nextElementSibling.style, { zIndex: 1, opacity: 0 });
      } else requestAnimationFrame(this.centerCardAnimation.bind(this));
    };
    if (this.cardElement) {
      requestAnimationFrame(this.rightCardAnimation.bind(this));
    }
  }

  __CardAnimation(previousIndex, nextIndex) {
    if (this.cardAnimated) return;

    if (!this.$) return;

    this.element = this.$.querySelectorAll('.card');

    if (!this.element) return;

    this.cardElement = this.element[this.lessPriceSuppliesItemVisible];
    this.translateXPosition = 0;
    this.previousCard = this.element[previousIndex];
    this.nextCard = this.element[nextIndex];

    this.cardAnimation = () => {
      const opacity = 1 + this.translateXPosition / 100;
      Object.assign(this.cardElement.style, {
        transform: `translateX(${this.translateXPosition}%)`,
        opacity,
        willChange: 'transform, opacity',
      });
      this.translateXPosition -= 5;
      if (opacity == 1) {
        Object.assign(this.previousCard.style, { zIndex: 1, opacity: 0 });
        Object.assign(this.nextCard.style, { zIndex: 2, opacity: 1 });

        requestAnimationFrame(this.cardAnimation.bind(this));
      } else if (opacity === 0) {
        Object.assign(this.cardElement.style, {
          transform: 'translateX(0)',
          opacity,
          zIndex: 1,
          willChange: null,
        });
        Object.assign(this.nextCard.style, { zIndex: 3, pointerEvents: 'all' });

        this.cardAnimated = false;
      } else requestAnimationFrame(this.cardAnimation.bind(this));
    };
    if (this.cardElement) {
      this.cardAnimated = true;
      requestAnimationFrame(this.cardAnimation.bind(this));
      this.lessPriceSuppliesItemVisible = nextIndex;
    }
  }
  // #endregion Protected
}

class GolfleetDashboardSpeedLimit {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetDashboardSpeedLimitController;
  }
}

angular
  .module('golfleet-dashboard-speed-limit', [
    'ngRedux',
    'cgNotify',
    'power-dropdown',
    'power-toolbar',
    'golfleet-dashboard-block',
    'power-single-checkbox',
    'power-loader',
    'golfleet-dashboard-error',
    'golfleet-dashboard-no-data',
    'power-popup',
    'power-toast',
    'golfleet-map-speed-limit',
  ])
  .component('golfleetDashboardSpeedLimit', new GolfleetDashboardSpeedLimit());

export { GolfleetDashboardSpeedLimitController };
