import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-grid/power-grid';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-header-selector/power-header-selector';
import '@power/power-components/components/power-navigation-date/power-navigation-date';
import { PowerReportTravelsController } from '@power/power-components/components/power-report-travels/power-report-travels';

import template from './golfleet-flex-report-travels.html';
import './golfleet-flex-report-travels.scss';

class GolfleetFlexReportTravelsController extends PowerReportTravelsController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }
  /* */

  /* Public */
  /* */

  /* Private */
  /* */

  /* Observers */
  /* */
}

class GolfleetFlexReportTravels {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetFlexReportTravelsController;
  }
}

angular
  .module('golfleet-flex-report-travels', [
    'ngRedux',
    'ng-tippy',
    'power-fab',
    'power-toolbar',
    'power-dropdown',
    'power-grid',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-header-selector',
    'power-navigation-date',
  ])
  .component('golfleetFlexReportTravels', new GolfleetFlexReportTravels());
