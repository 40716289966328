import angular from 'angular';
import 'angular-sanitize';

import '@power/power-components//directives/ng-resize/ng-resize';
import { PowerGridUtilizationController } from '@power/power-components/components/power-grid-utilization/power-grid-utilization';

import template from './golfleet-flex-grid-utilization.html';
import './golfleet-flex-grid-utilization.scss';

class GolfleetFlexGridUtilizationController extends PowerGridUtilizationController {
  static get $inject() {
    return [
      '$element',
      '$rootScope',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
      '$ngRedux',
    ];
  }

  constructor(
    $element,
    $rootScope,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
    $ngRedux,
  ) {
    super(
      $element,
      $rootScope,
      $scope,
      $state,
      $http,
      $timeout,
      $filter,
      commonServices,
      urlApi,
      $ngRedux,
    );

    Object.assign(this, {
      $: $element[0],
      $rootScope,
      $scope,
      $state,
      $http,
      $timeout,
      $filter,
      commonServices,
      urlApi,
      $ngRedux,
    });

    this.customActionsController = {
      showPositions: async () => {},
      showPopupDriver: async () => {},
      showEventMediasPopup: () => {},
    };
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();
  }
  /**/

  /* Public */
  /* */

  /* Private */
  /* */

  /* Observers */
  /* */
}

class GolfleetFlexGridUtilization {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
    };
    this.controller = GolfleetFlexGridUtilizationController;
  }
}

angular
  .module('golfleet-flex-grid-utilization', ['ngSanitize', 'ng-resize'])
  .component('golfleetFlexGridUtilization', new GolfleetFlexGridUtilization());
