import angular from 'angular';
import 'ng-redux';

import 'nouislider/distribute/nouislider.css';

import '@power/power-components/directives/ng-resize/ng-resize';

import '../golfleet-flex-grid-utilization/golfleet-flex-grid-utilization';
import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-map-utilization/power-map-utilization';
import '@power/power-components/components/power-navigation-date/power-navigation-date';
import '@power/power-components/components/power-pagination-register/power-pagination-register';
import '@power/power-components/components/power-card-utilization-list/power-card-utilization-list';
import '@power/power-components/components/power-popup-data-limit-reached/power-popup-data-limit-reached';
import '@power/power-components/components/power-popup-driver-ai-tiebreaker/power-popup-driver-ai-tiebreaker';
import '@power/power-components/components/power-popup-delete/power-popup-delete';
import '@power/power-components/components/power-media-request-utilization/power-media-request-utilization';

import { PowerReportUtilizationController } from '@power/power-components/components/power-report-utilization/power-report-utilization';

import template from './golfleet-flex-report-utilization.html';
import './golfleet-flex-report-utilization.scss';

class GolfleetFlexReportUtilizationController extends PowerReportUtilizationController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$rootScope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $scope,
    $rootScope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $rootScope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  /* */

  /* Public */
  /* */

  /* Private */
  // eslint-disable-next-line no-unused-vars
  _selectMapMode(mapMode) {}
  /* */

  /* Observers */
  /* */
}

class GolfleetFlexReportUtilization {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetFlexReportUtilizationController;
  }
}

angular
  .module('golfleet-flex-report-utilization', [
    'ngRedux',
    'ng-tippy',
    'ng-resize',
    'power-fab',
    'power-popup',
    'power-footer',
    'power-toolbar',
    'power-dropdown',
    'power-pagination',
    'power-map-utilization',
    'power-navigation-date',
    'golfleet-flex-grid-utilization',
    'power-pagination-register',
    'power-card-utilization-list',
    'power-popup-data-limit-reached',
    'power-popup-driver-ai-tiebreaker',
    'power-popup-delete',
    'power-media-request-utilization',
  ])
  .component('golfleetFlexReportUtilization', new GolfleetFlexReportUtilization());

export { GolfleetFlexReportUtilizationController };
