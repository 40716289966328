import angular from 'angular';

import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-share-tree/power-share-tree';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/components/power-crud/power-crud';
import '@power/power-components/components/power-period-preview/power-period-preview';
import '@power/power-components/components/power-accordion/power-accordion';

import { GolfleetRecordVehicleController } from '../golfleet-record-vehicle/golfleet-record-vehicle';

import '@power/power-components/directives/infinite-scroll/infinite-scroll';
import '@power/power-components/directives/ng-tippy/ng-tippy';

import template from './golfleet-flex-record-vehicle.html';
import './golfleet-flex-record-vehicle.scss';

class GolfleetFlexRecordVehicleController extends GolfleetRecordVehicleController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$scope',
      '$http',
      'recordServices',
      'urlApi',
      'urlWebSocket',
      'trackingRealTimeServices',
      'deviceDetector',
    ];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $ngRedux,
    $state,
    $scope,
    $http,
    recordServices,
    urlApi,
    urlWebSocket,
    trackingRealTimeServices,
    deviceDetector,
  ) {
    super(
      $element,
      $ngRedux,
      $state,
      $scope,
      $http,
      recordServices,
      urlApi,
      urlWebSocket,
      trackingRealTimeServices,
      deviceDetector,
    );
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();
  }
  /* */

  /* Public */
  /* */

  /* Private */
  async _getDataset() {
    this._toggleLoader(true);

    await this._getVehicleDataSet()
      .then(() => {
        if (!this.showVehicleSummary) {
          this._toggleLoader(false);
        }
      })
      .catch(() => {
        this._toggleLoader(false);
      });

    if (this.showVehicleSummary) {
      await this._getFleetPolicyDataset()
        .then(() => {
          this._toggleLoader(false);
        })
        .catch(() => {
          this._toggleLoader(false);
        });
    }
  }
  /* */
}

class GolfleetFlexRecordVehicle {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetFlexRecordVehicleController;
  }
}

angular
  .module('golfleet-flex-record-vehicle', [
    'ngRedux',
    'infinite-scroll',
    'ng-tippy',
    'power-dropdown',
    'power-footer',
    'power-popup',
    'power-share-tree',
    'power-toast',
    'power-toolbar',
    'power-crud',
    'power-period-preview',
    'power-accordion',
    'power-single-checkbox',
  ])
  .component('golfleetFlexRecordVehicle', new GolfleetFlexRecordVehicle());
