import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-map-ranking-details-speed-limit/power-map-ranking-details-speed-limit';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-toolbar-report/power-toolbar-report';
import '@power/power-components/directives/ng-resize/ng-resize';
import '@power/power-components/directives/ng-tippy/ng-tippy';

import '../golfleet-grid-speed-limit/golfleet-grid-speed-limit';

import { GolfleetReportRankingDetailsSpeedLimitController } from '../golfleet-report-ranking-details-speed-limit/golfleet-report-ranking-details-speed-limit';
import template from './golfleet-flex-report-ranking-details-speed-limit.html';
import './golfleet-flex-report-ranking-details-speed-limit.scss';

class GolfleetFlexReportRankingDetailsSpeedLimitController extends GolfleetReportRankingDetailsSpeedLimitController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }
  /* */

  /* Public */
  /* */

  /* Private */
  // eslint-disable-next-line no-unused-vars
  _selectMapMode(mapMode) {}

  _renderStepMode() {}

  /* */

  /* Observers */
  /* */
}

class GolfleetFlexReportRankingDetailsSpeedLimit {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetFlexReportRankingDetailsSpeedLimitController;
  }
}

angular
  .module('golfleet-flex-report-ranking-details-speed-limit', [
    'ngRedux',
    'ng-tippy',
    'power-fab',
    'power-toolbar-report',
    'golfleet-grid-speed-limit',
    'power-map-ranking-details-speed-limit',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-dropdown',
    'ng-resize',
  ])
  .component(
    'golfleetFlexReportRankingDetailsSpeedLimit',
    new GolfleetFlexReportRankingDetailsSpeedLimit(),
  );
