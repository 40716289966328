/* global H: true isIframe: true */

import { PowerMapPositionsHereProvider } from '@power/power-components/components/power-map-positions/providers/here/power-map-positions.here-provider';
import '@power/power-components/helpers/is-iframe/is-iframe';
import './golfleet-smart-map-positions.here-provider.scss';

class GolfleetSmartMapPositionsHereProvider extends PowerMapPositionsHereProvider {
  constructor(context, $element, $ngRedux, $rootScope, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();
  }
  /**/

  /* Public */
  /**/

  /* Private */
  _geoJsonToMarker({ geoJson, markerIcon }) {
    const [[lng, lat]] = geoJson.geometry.coordinates;
    const { eventoGerador } = geoJson.properties;
    const isVmaxEvent = eventoGerador && eventoGerador.toUpperCase() == 'VMAX';

    return new H.map.DomMarker(
      { lat, lng },
      {
        icon:
          markerIcon ||
          (geoJson.properties.markerIcon === 'RouteIcon'
            ? this._createRouteIcon
            : this._createMarkerIcon)(geoJson.properties),
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div id="${!isVmaxEvent ? 'mapPopupHeader' : 'mapPopupHeaderVmax'}">
              <span>${geoJson.properties.placaComApelido}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Data Hora:</b>
                <br>
                <span>${this._toDate(geoJson.properties.dataHora).toLocaleString('pt-BR', {
                  timeZone: 'America/Sao_Paulo',
                })}</span>
              </div>
              <div>
                <b>Velocidade:</b>
                <br>
                <div class="mapPopupBodyItem">
                  <span>
                    <i class="material-icons"
                      style="font-size: 11px; color: ${
                        !geoJson.properties.ignicao ? '#980A1A' : '#4AAE4E'
                      }">
                      lens
                    </i>
                    ${geoJson.properties.velocidade} Km/h
                    <i class="material-icons"
                      style="font-size: 11px; color: #949494;">
                      ${
                        !geoJson.properties.gpsStatus
                          ? 'signal_cellular_connected_no_internet_4_bar'
                          : 'network_cell'
                      }
                    </i>
                  </span>
                  ${!isVmaxEvent ? '' : "<span class='vmaxTag'>VMAX</span>"}
                </div>
              </div>
              ${
                isIframe()
                  ? ''
                  : `
                <div style="margin-top:8px; text-align:center">
                  <a class="gs-link"
                    target="_blank"
                    href="https://maps.google.com/maps?layer=c&q=${lat},${lng}&cbll=${lat},${lng}&cbp=11,0,0,0,0&z=17&ll=${lat},${lng}">
                    Ver no StreetView
                  </a>
                </div>
              `
              }
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${Number(lat).toFixed(4)} </span>
              <span> Lon: ${Number(lng).toFixed(4)} </span>
            </div>
          `,
        },
      },
    );
  }
  /**/

  /* Observers */
  /**/
}

export { GolfleetSmartMapPositionsHereProvider };
