import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/components/power-grid-unitary-violation/power-grid-unitary-violation';
import '@power/power-components/components/power-map-violation/power-map-violation';
import '@power/power-components/components/power-photo-indexation/power-photo-indexation';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/directives/ng-resize/ng-resize';
import { PowerReportUnitaryViolationController } from '@power/power-components/components/power-report-unitary-violation/power-report-unitary-violation';

import template from './golfleet-flex-report-unitary-violation.html';
import './golfleet-flex-report-unitary-violation.scss';

class GolfleetFlexReportUnitaryViolationController extends PowerReportUnitaryViolationController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
      'identificationTypeServices',
    ];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
    identificationTypeServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
      identificationTypeServices,
    );
    this.toastText = '';
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }
  /* */

  /* Public */
  /* */

  /* Private */
  // eslint-disable-next-line no-unused-vars, no-empty-function
  async _renderStepMode(index) {}
  /* */

  /* Observers */
  /* */
}

class GolfleetFlexReportUnitaryViolation {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetFlexReportUnitaryViolationController;
  }
}

angular
  .module('golfleet-flex-report-unitary-violation', [
    'ngRedux',
    'power-fab',
    'power-dropdown',
    'power-toolbar',
    'power-grid-unitary-violation',
    'power-map-violation',
    'power-photo-indexation',
    'power-footer',
    'power-pagination',
    'power-toast',
    'ng-resize',
  ])
  .component('golfleetFlexReportUnitaryViolation', new GolfleetFlexReportUnitaryViolation());
