import angular from 'angular';
import 'ng-redux';

import '../power-fab/power-fab';
import '../power-toolbar/power-toolbar';
import '../power-dropdown/power-dropdown';
import '../power-grid/power-grid';
import '../power-footer/power-footer';
import '../power-pagination/power-pagination';
import '../power-popup/power-popup';
import '../power-header-selector/power-header-selector';
import '../power-navigation-date/power-navigation-date';
import { PowerReportController } from '../power-report/power-report';

import template from './power-report-travels.html';
import './power-report-travels.scss';

class PowerReportTravelsController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      requestDataset: this.requestDataset.bind(this),
    });

    const matchTablet = window.matchMedia('(max-width: 768px)');
    const matchTabletEvt = evt =>
      evt.matches && this.stateConfig.viewMode == 'split' ? this.changeView('grid') : null;
    matchTablet.addListener(matchTabletEvt);

    this.$.addEventListener('share', this.onShareEvent);
    this.$.addEventListener('delete', this.onDeleteEvent);

    this.$scope.$on('goToLink', this._goToLink.bind(this));
    this.$scope.$on('UPDATE_ROUTE', () => this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' }));

    if (this.stateConfig.viewMode) {
      this.changeView(this.stateConfig.viewMode);
    } else {
      this.changeView('grid');
    }

    if (this.stateConfig.isCustomReport && this.stateConfig.customReportId) {
      this.$scope.$on('getDatasetReady', () => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );

        this.$.querySelector('power-navigation-date').setup({
          path: this.stateConfig.navigation.date,
          minPath: this.stateConfig.navigation.dateMinPath,
        });

        const showReportNotFoundMessage = () => {
          this.toastText = 'O relatório selecionado não existe ou foi removido';
          this.$.querySelector('power-toast#report-toast').toggle(true);
        };

        this.reportServices
          .getUserReportConfiguration({
            _id: this.stateConfig.customReportId,
          })
          .then(success => {
            if (success.status && success.status == 200 && success.data.data) {
              const { data } = success.data;

              if (data) {
                this.userReportConfiguration = {
                  ...data,
                  filterConfig: JSON.parse(data.filterConfig),
                  gridConfig: JSON.parse(data.gridConfig),
                };

                return;
              }
            }
            this.userReportConfiguration = null;
            showReportNotFoundMessage();
          })
          .catch(() => {
            this.userReportConfiguration = null;
            showReportNotFoundMessage();
          })
          .finally(() => {
            if (!this.stateConfig.filterConfig || this.stateConfig.filterConfig.length == 0) {
              this.$scope.$broadcast('getHeaders', {
                screenName: this.stateConfig.screenName,
                gridName: this.stateConfig.gridName,
                userGridConfig: this.userReportConfiguration
                  ? this.userReportConfiguration.gridConfig
                  : null,
              });

              this._getStateConfig();
            } else {
              const stateGridConfig = this.stateConfig.gridConfig;

              this.$scope.$broadcast('getHeaders', {
                screenName: this.stateConfig.screenName,
                gridName: this.stateConfig.gridName,
                page: !this.userReportConfiguration ? stateGridConfig.page : undefined,
                pageSize: !this.userReportConfiguration ? stateGridConfig.pageSize : undefined,
                userGridConfig:
                  !this.isDrillDownRoute && !!this.userReportConfiguration
                    ? this.userReportConfiguration.gridConfig
                    : undefined,
              });
              this.$scope.$broadcast('getDataset', {
                filter: {
                  conditions: this.stateConfig.filterConditions,
                },
                isPaginated: stateGridConfig.backPagination,
                ...this.stateConfig.getDataFixedParams,
              });

              document
                .querySelector('#app-component')
                .querySelector('power-filter-menu')
                .validateFilters();
            }
          });
      });
    } else if (!this.stateConfig.filterConfig || this.stateConfig.filterConfig.length == 0) {
      this.$scope.$on('getDatasetReady', () => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );
        this.$scope.$broadcast('getHeaders', {
          screenName: this.stateConfig.screenName,
          gridName: this.stateConfig.gridName,
        });
        this._getStateConfig();
      });
    } else {
      this.$scope.$on('getDatasetReady', () => {
        this.$.querySelector('power-navigation-date').setup({
          path: this.stateConfig.navigation.date,
          minPath: this.stateConfig.navigation.dateMinPath,
        });

        this.$scope.$broadcast('getDataset', {
          filter: {
            conditions: this.stateConfig.filterConditions,
          },
          navigation: {
            date: this.stateConfig.navigation.date[this.stateConfig.navigation.date.length - 1],
          },
          isPaginated: this.stateConfig.gridConfig.backPagination,
          ...this.stateConfig.getDataFixedParams,
        });

        document.querySelector('#app-component').querySelector('power-filter-menu');
      });
    }
  }
  /* */

  /* Public */
  changePage(page) {
    this.$scope.$broadcast('changePage', {
      page,
      payload: {
        filter: {
          conditions: this.stateConfig.filterConditions,
        },
        navigation: {
          date: this.stateConfig.navigation.date[this.stateConfig.navigation.date.length - 1],
        },
        isPaginated: this.stateConfig.gridConfig.backPagination,
        ...this.stateConfig.getDataFixedParams,
      },
    });
  }

  changePageSize(pageSize) {
    this.$timeout(() => {
      this.$scope.$broadcast('changePageSize', {
        pageSize,
        payload: {
          filter: {
            conditions: this.stateConfig.filterConditions,
          },
          navigation: {
            date: this.stateConfig.navigation.date[this.stateConfig.navigation.date.length - 1],
          },
          isPaginated: this.stateConfig.gridConfig.backPagination,
          ...this.stateConfig.getDataFixedParams,
        },
      });
    });
  }

  async requestDataset(keepPage) {
    const [calendarFilter] = this.stateConfig.filterConfig.filter(
      filter => filter.field === 'date',
    );

    const [granularityFilter] = this.stateConfig.filterConfig.filter(
      filter => filter.field === 'granularity',
    );

    if (
      this.lastState &&
      this.lastState.stateConfig &&
      this.lastState.stateConfig.navigation &&
      this.stateConfig.navigation.date.length === 0
    ) {
      this.stateConfig.navigation = Object.clone({}, this.lastState.stateConfig.navigation);

      await this.$.querySelector('power-navigation-date').setup({
        path: this.stateConfig.navigation.date,
      });

      if (this.stateConfig.getDataFixedParams.dateGranularity) {
        this.$.querySelector('power-navigation-date').setPathGranularity({
          granularity: this.stateConfig.getDataFixedParams.dateGranularity,
        });
      }
    } else if (
      this.stateConfig.navigation.date.length === 0 ||
      calendarFilter.condition.value[0] !== this.stateConfig.navigation.date[0].startDate ||
      calendarFilter.condition.value[0] !== this.stateConfig.navigation.date[0].endDate
    ) {
      let minPath = 0;
      const path = [];

      if (granularityFilter && granularityFilter.condition.value[0]) {
        const [granularity] = granularityFilter.condition.value;
        const granularityMap = { sumarizado: 0, mes: 0, dia: 1, hora: 2, unitario: 3 };

        minPath = granularityMap[granularity];

        path.push({
          endDate: calendarFilter.condition.value[1],
          startDate: calendarFilter.condition.value[0],
          granularity,
        });
      } else {
        path.push({
          endDate: calendarFilter.condition.value[1],
          startDate: calendarFilter.condition.value[0],
          granularity: 'mes',
        });
      }

      this.stateConfig.navigation = { date: path, dateMinPath: minPath };

      this.$.querySelector('power-navigation-date').setup({
        path: this.stateConfig.navigation.date,
        minPath,
      });
    }

    if (this.stateConfig.getDataFixedParams?.dateGranularity && !this.hasNavigationDate) {
      this.hasNavigationDate = true;
      return;
    }

    const payload = {
      filter: {
        conditions: this.stateConfig.filterConditions,
      },
      navigation: {
        date: this.stateConfig.navigation.date[this.stateConfig.navigation.date.length - 1],
      },
      isPaginated: this.stateConfig.gridConfig.backPagination,
    };

    if (!keepPage) payload.page = 1;

    this.$scope.$broadcast(
      'getDataset',
      Object.assign(payload, this.stateConfig.getDataFixedParams),
    );
  }
  /* */

  /* Private */
  /* */

  /* Observers */
  __updateDateDrilldownPath() {
    const datePath = this.$.querySelector('power-navigation-date').getPath();
    this.stateConfig.navigation.date = datePath;

    this.$scope.$broadcast('getDataset', {
      filter: {
        conditions: this.stateConfig.filterConditions,
      },
      navigation: {
        date: datePath[datePath.length - 1],
      },
      isPaginated: this.stateConfig.gridConfig.backPagination,
      ...this.stateConfig.getDataFixedParams,
    });
  }

  __syncStateDrilldown() {
    if (this.stateConfig && this.stateConfig.gridConfig) {
      this.stateConfig.gridConfig.page = 1;
    }
  }
  /* */
}

class PowerReportTravels {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerReportTravelsController;
  }
}

angular
  .module('power-report-travels', [
    'ngRedux',
    'ng-tippy',
    'power-fab',
    'power-toolbar',
    'power-dropdown',
    'power-grid',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-header-selector',
    'power-navigation-date',
  ])
  .component('powerReportTravels', new PowerReportTravels());

export { PowerReportTravelsController };
