/* global L: true isIframe: true */
import moment from 'moment';

import { PowerMapLeafletProvider } from '../../../power-map/providers/leaflet/power-map.leaflet-provider';
import '../../../../helpers/is-iframe/is-iframe';
import './power-map-positions.leaflet-provider.scss';

class PowerMapPositionsLeafletProvider extends PowerMapLeafletProvider {
  constructor(context, $element, $ngRedux, $rootScope, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);

    Object.assign(this, { $rootScope });

    this.routeTimelineInterval = null;
    this.routeTimelinePosition = 0;

    this.routeMarkers = null;
    this.routeLinestrings = null;
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      openPopup: this.openPopup.bind(this),
      setTimelineDataset: this.setTimelineDataset.bind(this),
      toggleTimelineControl: this.toggleTimelineControl.bind(this),
      updateTimelinePosition: this.updateTimelinePosition.bind(this),
    });

    super.$onInit();
  }

  $onDestroy() {
    clearInterval(this.context.routeTimelineEvent);
  }
  /**/

  /* Public */
  removeLayers(layerNameList = ['default']) {
    const removingTimeline =
      layerNameList.filter(layer => layer == 'routeMarkers' || layer == 'routeLinestrings').length <
      layerNameList.length;
    if (this.context.routeTimelineEvent && removingTimeline) {
      clearInterval(this.context.routeTimelineEvent);
      Object.assign(this, {
        routeTimelineEvent: null,
        routeTimelinePosition: this.routeMarkers.length - 1,
      });
    }
    super.removeLayers(layerNameList);
  }

  toggleTimelineControl(show) {
    this.$.querySelector('#route-timeline-control').style.visibility = show ? 'inherit' : 'hidden';
  }

  setTimelineDataset({ markers, linestrings }) {
    Object.assign(this, {
      routeMarkers: markers,
      routeLinestrings: linestrings,
      routeTimelinePosition: markers.length - 1,
    });
    this._renderRouteTimeline();
  }

  updateTimelinePosition(evtParams) {
    this.routeTimelinePosition = evtParams.pathPosition;

    if (
      'routeMarkers' in this.mapFeatureLayers &&
      this.mapFeatureLayers.routeMarkers.getLayers().length > 0
    ) {
      const marker = this.mapFeatureLayers.routeMarkers.getLayers()[this.routeTimelinePosition];

      marker.openPopup();
      marker._bringToFront();

      this.mapLayer.setView(
        marker.getLatLng(),
        this.routeTimelinePosition == 1 ? 16 : this.mapLayer.getZoom(),
        { animate: true },
      );
    }
  }
  /**/

  /* Private */
  _createMarkerIcon({ color, icon, ignicao: ignition, direcao: direction }) {
    if (!ignition || direction === null) {
      return super._createMarkerIcon({ color, icon });
    }

    const createIcon = iconString => {
      if (!iconString) return '';
      return `
        <div style="color: #fff;cursor: pointer;transform: rotate(-${direction}deg);">
          <i class="material-icons" style="cursor: pointer;">
            ${iconString}
          </i>
        </div>
      `;
    };

    return L.divIcon({
      className: 'leaflet-marker-icon',
      iconSize: [20, 20],
      html: `
        <div style="margin: -10px 0px 0px -10px; z-index: 0; position: absolute;">
          <div style="width:40px; height:40px; transform: rotate(${direction}deg);">
            <svg xmlns="https://www.w3.org/2000/svg" width="40" height="60" style="margin-top:-20px;">
              <foreignObject x="0" y="0" width="40" height="60" style="">
                <div style="display:flex;justify-content:center;align-items: center;width: 100%;height: 100%;flex-direction: column;">
                  <i class="material-icons" style="color:${
                    color || 'var(--primary-color)'
                  };cursor: pointer;font-size: 24px;margin-bottom:1px; margin-top: -4px;">
                    navigation
                  </i>
                  <div style="background:${
                    color || 'var(--primary-color)'
                  };border-radius: 100%;display:flex;justify-content:center;align-items: center;height: 32px;width: 32px;">
                    ${createIcon(icon)}
                  </div>
                </div>
              </foreignObject>
            </svg>
          </div>
        </div>
        `,
    });
  }

  _createRouteIcon(properties) {
    const svgSize = properties.first || properties.last ? 36 : 24;
    const svgSizeWithShadow = properties.first || properties.last ? 40 : 28;

    const { eventoGerador } = properties;
    const isVmaxEvent = eventoGerador && eventoGerador.toUpperCase() == 'VMAX';
    const iconColor = isVmaxEvent ? '#DECE00' : '#FFF';

    if (properties.status == 4) {
      return L.divIcon({
        className: 'leaflet-route-icon',
        iconSize: [svgSizeWithShadow / 2, svgSizeWithShadow / 2],
        html: `
            <svg xmlns="https://www.w3.org/2000/svg"
              viewBox="0 0 ${svgSizeWithShadow} ${svgSizeWithShadow}"
              width="${svgSizeWithShadow}"
              height="${svgSizeWithShadow}"
              style="margin: -${svgSizeWithShadow / 4}px 0 0 -${svgSizeWithShadow / 4}px">
              <circle
                r="${svgSize / 4}"
                cx="${svgSizeWithShadow / 2}"
                cy="${svgSizeWithShadow / 2}"
                fill="#2196f3">
              </circle>
              ${
                properties.first
                  ? `<foreignObject x="15" y="9" width="10" height="20"><span style="color: #FFF; font-size: 14px; line-height: ${
                      svgSizeWithShadow / 2
                    }px;"> A </span></foreignObject>`
                  : ''
              }
              ${
                properties.last
                  ? `<foreignObject x="15" y="10" width="10" height="20"><span style="color: #FFF; font-size: 14px; line-height: ${
                      svgSizeWithShadow / 2
                    }px;"> B </span></foreignObject>`
                  : ''
              }
            </svg>
        `,
      });
    }

    if (properties.status == 2 || properties.status == 3) {
      return L.divIcon({
        className: 'leaflet-route-icon',
        iconSize: [svgSizeWithShadow / 2, svgSizeWithShadow / 2],
        html: `
          <svg xmlns="https://www.w3.org/2000/svg"
            viewBox="0 0 ${svgSizeWithShadow} ${svgSizeWithShadow}"
            width="${svgSizeWithShadow}"
            height="${svgSizeWithShadow}"
            style="margin: -${svgSizeWithShadow / 4}px 0 0 -${svgSizeWithShadow / 4}px">
            <rect
              height="${(svgSize * 2) / 3}"
              x="${svgSize / 6 + 2}"
              y="${svgSize / 6 + 2}" rx="3"
              ry="3"
              width="${(svgSize * 2) / 3}"
              stroke-width="${svgSize / 6}"
              stroke="#2196f3" fill="${iconColor}">
            </rect>
            ${
              properties.first
                ? `<foreignObject x="15" y="9" width="10" height="20"><span style="color: #2196f3; font-size: 14px; line-height: ${
                    svgSizeWithShadow / 2
                  }px;"> A </span></foreignObject>`
                : ''
            }
            ${
              properties.last
                ? `<foreignObject x="15" y="10" width="10" height="20"><span style="color: #2196f3; font-size: 14px; line-height: ${
                    svgSizeWithShadow / 2
                  }px;"> B </span></foreignObject>`
                : ''
            }
          </svg>
      `,
      });
    }

    if (!properties.ignicao || properties.first || properties.last) {
      return L.divIcon({
        className: 'leaflet-route-icon',
        iconSize: [svgSizeWithShadow / 2, svgSizeWithShadow / 2],
        html: `
          <svg xmlns="https://www.w3.org/2000/svg"
            viewBox="0 0 ${svgSizeWithShadow} ${svgSizeWithShadow}"
            width="${svgSizeWithShadow}"
            height="${svgSizeWithShadow}"
            style="margin: -${svgSizeWithShadow / 4}px 0 0 -${svgSizeWithShadow / 4}px">
            <circle
              r="${svgSize / 3}"
              cx="${svgSizeWithShadow / 2}"
              cy="${svgSizeWithShadow / 2}"
              stroke-width="${svgSize / 6}"
              stroke="#2196f3"
              fill="${iconColor}">
            </circle>
            ${
              properties.first
                ? `<foreignObject x="15" y="9" width="10" height="20"><span style="color:#2196f3; font-size: 14px; line-height: ${
                    svgSizeWithShadow / 2
                  }px;"> A </span></foreignObject>`
                : ''
            }
            ${
              properties.last
                ? `<foreignObject x="15" y="10" width="10" height="20"><span style="color: #2196f3; font-size: 14px; line-height: ${
                    svgSizeWithShadow / 2
                  }px;"> B </span></foreignObject>`
                : ''
            }
          </svg>
      `,
      });
    }

    return L.divIcon({
      className: 'leaflet-route-icon',
      iconSize: [svgSizeWithShadow / 2, svgSizeWithShadow / 2],
      html: `
        <div style="
          margin: -${svgSizeWithShadow / 4}px 0 0 -${svgSizeWithShadow / 4}px;
          z-index: 0;
          position: absolute;"
        >
          <div style="
            width:${svgSizeWithShadow}px;
            height:${svgSizeWithShadow}px;
            transform: rotate(${properties.direcao}deg);"
          >
            <svg xmlns="https://www.w3.org/2000/svg"
              viewBox="0 0 ${svgSizeWithShadow} ${svgSizeWithShadow * 2 - 8}"
              width="${svgSizeWithShadow}"
              height="${svgSizeWithShadow * 2 - 8}"
              style="margin-top: -${svgSizeWithShadow - 8}px">
              <foreignObject x="0" y="0" width="28" height="48" style="">
                <div style="display:flex;justify-content:center;align-items: center;width: 100%;height: 100%;flex-direction: column;">
                  <i class="material-icons" style="${
                    isVmaxEvent ? 'opacity: 0' : 'color:#2196f3'
                  } ;cursor: pointer;font-size: 24px; margin-bottom:0; margin-top: -4px;">
                    navigation
                  </i>
                  <div style="background:${iconColor};
                    border: ${svgSize / 6}px solid #2196f3;
                    border-radius: 100%;
                    display:flex;
                    justify-content:center;
                    align-items: center;
                    height: ${svgSize / 2}px;
                    width: ${svgSize / 2}px;">
                  </div>
                </div>
              </foreignObject>
            </svg>
          </div>
        </div>
    `,
    });
  }

  _geoJsonToMarker({ geoJson, markerIcon }) {
    const { eventoGerador } = geoJson.properties;
    const isVmaxEvent = eventoGerador && eventoGerador.toUpperCase() == 'VMAX';

    return L.geoJSON(geoJson, {
      pointToLayer: (feature, latlng) =>
        L.marker(latlng, {
          ...feature.properties,
          icon:
            markerIcon ||
            (geoJson.properties.markerIcon === 'RouteIcon'
              ? this._createRouteIcon
              : this._createMarkerIcon)(geoJson.properties),
        }).bindPopup(`
          <div id="${!isVmaxEvent ? 'mapPopupHeader' : 'mapPopupHeaderVmax'}">
            <span>${feature.properties.placaComApelido}</span>
          </div>
          <div id="mapPopupBody">
            <div style="display: ${feature.properties.logradouro ? 'block' : 'none'}">
              <b>Endereço:</b>
              <br>
              <span>${feature.properties.logradouro}</span>
            </div>
            <div style="display: ${feature.properties.municipio ? 'block' : 'none'}">
              <b>Cidade - Estado:</b>
              <br>
              <span>${feature.properties.municipio} - ${feature.properties.estado}</span>
            </div>
            <div>
              <b>Data Hora:</b>
              <br>
              <span>${this._toDate(feature.properties.dataHora).toLocaleString('pt-BR', {
                timeZone: 'America/Sao_Paulo',
              })}</span>
            </div>
            <div>
              <b>Velocidade:</b>
              <br>
              <div class="mapPopupBodyItem">
                <span>
                  <i class="material-icons"
                    style="font-size: 11px; color: ${
                      !feature.properties.ignicao ? '#980A1A' : '#4AAE4E'
                    }">
                    lens
                  </i>
                  ${feature.properties.velocidade} Km/h
                  <i class="material-icons"
                    style="font-size: 11px; color: #949494;">
                    ${
                      !feature.properties.gpsStatus
                        ? 'signal_cellular_connected_no_internet_4_bar'
                        : 'network_cell'
                    }
                  </i>
                </span>
                ${!isVmaxEvent ? '' : "<span class='vmaxTag'>VMAX</span>"}
              </div>
            </div>
            <div>
              <b>Condutor:</b>
              <br>
              <span>${feature.properties.condutor || 'Não identificado'}</span>
            </div>
            ${
              isIframe()
                ? ''
                : `
              <div style="margin-top:8px; text-align:center">
                <a class="gs-link"
                  target="_blank"
                  href="https://maps.google.com/maps?layer=c&q=${latlng.lat},${latlng.lng}&cbll=${latlng.lat},${latlng.lng}&cbp=11,0,0,0,0&z=17&ll=${latlng.lat},${latlng.lng}">
                  Ver no StreetView
                </a>
              </div>
            `
            }
          </div>
          <div id="mapPopupFooter">
            <span> Lat: ${Number(latlng.lat).toFixed(4)} </span>
            <span> Lon: ${Number(latlng.lng).toFixed(4)} </span>
          </div>
				`),
    }).getLayers()[0];
  }

  _geoJsonToLGeoJson({ geoJson, markerIcon }) {
    return L.geoJSON(geoJson, {
      style: { color: geoJson.properties.color },
      onEachFeature: (feature, layer) => {
        layer.bindPopup(`
          <div id="mapPopupHeader">
            <span>${feature.properties.description}</span>
          </div>
          <div id="mapPopupBody">
            <div>
              <b>Descrição:</b>
              <br>
              <span>${feature.properties.extendedDescription || '---'}</span>
            </div>
            <div>
              <b>Categoria:</b>
              <br>
              <span>${feature.properties.categoryName || '---'}</span>
            </div>
            <div>
              <b>Privacidade:</b>
              <br>
              <span>${feature.properties.privacy || '---'}</span>
            </div>
            <div>
              <b>Status:</b>
              <br>
              <span>${feature.properties.providerStatusDesc || '---'}</span>
            </div>
          </div>
          <div id="mapPopupFooter">
            <span> Lat: ${parseFloat(feature.properties.latitude).toFixed(4)} </span>
            <span> Lon: ${parseFloat(feature.properties.longitude).toFixed(4)} </span>
          </div>
        `);
      },
      pointToLayer: (feature, latlng) => {
        switch (feature.type) {
          case 'Polygon':
            return L.polygon(latlng, feature.properties);
          case 'Polyline':
            return L.polyline(latlng, feature.properties);
          case 'Rectangle':
            return L.rectangle(latlng, feature.properties);
          default:
            return feature.properties.radius
              ? L.circle(latlng, feature.properties)
              : L.marker(latlng, {
                  ...feature.properties,
                  icon: markerIcon || this._createMarkerIcon(feature.properties),
                });
        }
      },
    }).getLayers()[0];
  }

  _toggleRouteTimeline() {
    if (!this.routeMarkers || !this.routeLinestrings) {
      // eslint-disable-next-line no-console
      return console.warn('Missing routeMarkers or/and routeLinestrings Layers');
    }

    if (!this.context.routeTimelineEvent) {
      this.context.routeTimelineEvent = setInterval(() => {
        if (this.routeTimelinePosition < this.routeMarkers.length - 1) {
          this.routeTimelinePosition += 1;
          this.$scope.$emit('updateTimelineSlider', {
            value: this.routeTimelinePosition,
          });
        } else {
          this._endRouteTimeline();
        }
      }, 2000);

      if (this.routeTimelinePosition == this.routeMarkers.length - 1)
        this.routeTimelinePosition = 0;
      else this.routeTimelinePosition += 1;
      this.$scope.$emit('updateTimelineSlider', {
        value: this.routeTimelinePosition,
      });
    } else {
      clearInterval(this.context.routeTimelineEvent);
      this.context.routeTimelineEvent = null;
      this._renderRouteTimeline();
    }

    return null;
  }

  _endRouteTimeline() {
    clearInterval(this.context.routeTimelineEvent);
    this.context.routeTimelineEvent = null;

    Object.assign(this, {
      routeTimelinePosition: this.routeMarkers.length - 1,
    });

    if (this.$scope.$$phase === null && this.$rootScope.$$phase === null) {
      this.$scope.$apply();
    }

    this.$scope.$emit('updateTimelineSlider', {
      value: this.routeMarkers.length - 1,
    });

    this._renderRouteTimeline();
  }

  _renderRouteTimeline() {
    return this.renderDataset({
      dataset: this.routeMarkers,
      layerName: 'routeMarkers',
      type: 'MarkerFeatureGroup',
      useCluster: false,
      clusterColor: '#2196f3',
    })
      .then(() =>
        this.renderDataset({
          dataset: this.routeLinestrings,
          layerName: 'routeLinestrings',
          type: 'LinestringFeatureGroup',
        }),
      )
      .then(() => {
        if (!this.context.routeTimelineEvent) {
          this.fitLayers(['routeLinestrings']);
        } else if ('getLayers' in this.mapFeatureLayers.routeMarkers) {
          const markersLayers = this.mapFeatureLayers.routeMarkers.getLayers();

          if (markersLayers.length < 1) return;

          const lastMarker = markersLayers[markersLayers.length - 1];
          this.mapLayer.setView(lastMarker.getLatLng(), 16, { animate: true });

          lastMarker.openPopup();
          lastMarker._bringToFront();
        }
      });
  }

  _toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }

  _getDirectionStyle(isVmaxEvent, color) {
    return isVmaxEvent ? 'opacity: 0' : `color:${color}`;
  }
  /**/

  /* Observers */
  /**/
}

export { PowerMapPositionsLeafletProvider };
