/* eslint-disable no-new */
/* eslint-disable no-prototype-builtins */

import angular from 'angular';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import Treemap from 'highcharts/modules/treemap';
import 'highcharts-regression';
import 'ng-redux';
import '@cgross/angular-notify/dist/angular-notify.min.css';
import '@cgross/angular-notify/dist/angular-notify.min.js';

import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '../golfleet-dashboard-block/golfleet-dashboard-block';
import '@power/power-components/components/power-single-checkbox/power-single-checkbox';
import '../power-loader/power-loader';
import '../golfleet-dashboard-error/golfleet-dashboard-error';
import '../golfleet-dashboard-no-data/golfleet-dashboard-no-data';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-toast/power-toast';
import '../golfleet-map-speed-limit/golfleet-map-speed-limit';
import '@power/power-components/helpers/format-number/format-number';

import { GolfleetDashboardSpeedLimitController } from '../golfleet-dashboard-speed-limit/golfleet-dashboard-speed-limit';
import '@power/power-components/helpers/is-iframe/is-iframe';

import template from './golfleet-flex-dashboard-speed-limit.html';
import './golfleet-flex-dashboard-speed-limit.scss';

Exporting(Highcharts);
OfflineExporting(Highcharts);
Treemap(Highcharts);

class GolfleetFlexDashboardSpeedLimitController extends GolfleetDashboardSpeedLimitController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$scope',
      '$rootScope',
      '$http',
      'urlApi',
      'filterServices',
      'commonServices',
      'dashboardServices',
      '_today',
      '_months',
      '$timeout',
      '$interval',
      'notify',
    ];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $ngRedux,
    $state,
    $scope,
    $rootScope,
    $http,
    urlApi,
    filterServices,
    commonServices,
    dashboardServices,
    _today,
    _months,
    $timeout,
    $interval,
    notify,
  ) {
    super(
      $element,
      $ngRedux,
      $state,
      $scope,
      $rootScope,
      $http,
      urlApi,
      filterServices,
      commonServices,
      dashboardServices,
      _today,
      _months,
      $timeout,
      $interval,
      notify,
    );
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();
  }
  /* */

  /* Public */
  /* */

  /* Private */
  /* */

  // #region Protected
  // #endregion Protected
}

class GolfleetFlexDashboardSpeedLimit {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetFlexDashboardSpeedLimitController;
  }
}

angular
  .module('golfleet-flex-dashboard-speed-limit', [
    'ngRedux',
    'cgNotify',
    'power-dropdown',
    'power-toolbar',
    'golfleet-dashboard-block',
    'power-single-checkbox',
    'power-loader',
    'golfleet-dashboard-error',
    'golfleet-dashboard-no-data',
    'power-popup',
    'power-toast',
    'golfleet-map-speed-limit',
  ])
  .component('golfleetFlexDashboardSpeedLimit', new GolfleetFlexDashboardSpeedLimit());
