/* eslint new-cap: ["error", { "newIsCap": false }] */
import moment from 'moment';
import noUiSlider from 'nouislider';
import $ from 'jquery';
import 'ng-redux';

import '../power-fab/power-fab';
import '../power-dropdown/power-dropdown';
import '../power-footer/power-footer';
import '../power-pagination/power-pagination';
import '../power-popup/power-popup';
import '../power-period/power-period';
import '../power-radio-button-group/power-radio-button-group';
import '../power-toast/power-toast';
import '../power-toolbar/power-toolbar';
import '../../directives/ng-tippy/ng-tippy';
import '../../directives/infinite-scroll/infinite-scroll';
import { FilterCondition } from '../power-filter/power-filter';
import { PowerReportController } from '../power-report/power-report';

import '../power-card-alert/power-card-alert';
import '../power-custom-checkbox-list/power-custom-checkbox-list';
import '../power-simple-card-list/power-simple-card-list';

class EventItem {
  constructor(
    instance,
    index,
    id,
    idItem,
    icon,
    description,
    unsupportedInfo,
    typeId,
    tdeId,
    simultaneous,
    complementId,
    complementDescription,
    value,
    geofence,
    tooltip,
    hasGeoFences,
    isSimultaneous,
  ) {
    Object.assign(this, { instance });

    const geofences = Object.clone([], this.instance.eventsTab.geofences);
    if (geofence) {
      geofence.forEach(geo => {
        geofences
          .filter(item => item.id == geo.id)
          .map(item => {
            item.selected = true;

            return item;
          });
      });
    }

    this.index = index;
    this.id = id;
    this.idItem = idItem;
    this.icon = icon;
    this.typeId = typeId;
    this.tdeId = tdeId;
    this.description = description;
    this.unsupportedInfo = unsupportedInfo;
    this.hasValue = typeId == 4;
    this.hasGeofence = true;
    this.simultaneous = simultaneous;
    this.complementId = complementId;
    this.complementDescription = complementDescription;
    this.value = value || null;
    this.geofence = geofence || [];
    this.geofences = geofences;
    this.tooltip = tooltip;
    this.hasGeoFences = hasGeoFences;
    this.isSimultaneous = isSimultaneous;
  }
}

class UnsupportedInfo {
  constructor(list) {
    this.count = list.length;
    this.list = list;
  }
}

class RecipientsItem {
  constructor(id, item) {
    this.id = id;
    this.item = item;
  }
}

class RecipientsOptions {
  constructor(id, description, value, icon, obj, selected = false) {
    this.id = id;
    this.description = description;
    this.value = value;
    this.icon = icon;
    this.originalObj = obj;
    this.selected = selected;
  }
}

class PowerReportAlertsController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
      '$sce',
      '$rootScope',
    ];
  }

  /**
   * Creates an instance of ReportAlertsController.
   * @memberof ReportAlertsController
   */
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
    $sce,
    $rootScope,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    Object.assign(this, { $sce, $rootScope });

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        isSingleSignon: behavior.session.isSingleSignon,
        session: behavior.session,
      }),
    )(this);

    this.simultaneousTippyTitle = '';
    this.alertCanBeSimultaneous = true;
    this.statusPopupAlert = 0; // 0. Loading, 1. Add, 2. Edit, 3. Error
    this.popupAlertTabSelected = 1; // 1. Regras, 2. Eventos, 3. Recipientes
    this.primaryColorSelected = '';
    this.newEmailActiveView = false;
    this.textareaNewEmailValue = '';
    this.initialNewEmailText =
      'Para incluir multiplos destinatários, coloque os emails separados por vírgula ou ponto e vírgula.';
    this.newEmailText = null;
    this.newEmailSuccessIcon = false;
    this.hasInvalidEmails = false;
    this.alertPeriodConfig = [];
    this.toastObj = {
      text: '',
    };
    this.bodyBlocked = false;
    this.deleteItemInfo = null;
    this.statusElements = {
      applyOn: 0,
      conditions: 0,
      recipients: 0,
    }; // 0. Loading, 1. OK, 2. Error
    this.popupUnsupportedInfo = [];
    this.dropdownOptionsAlert = [
      {
        id: 1,
        type: 'Radio',
        title: 'Aplicar alerta em',
        titleDescription: 'Aplicar alerta em',
        icons: {
          before: 'directions_car',
          after: null,
        },
        placeholder: 'Veículos ou condutores',
        enabled: true,
        loading: true,
        method: 'Filter/GetDefaultFilter',
        methodAdm: 'FilterAdm/GetDefaultFilter',
        methodParams: {
          id: 0,
        },
        slot: 'applyOnType',
        childId: 2,
        optSelected: 0,
        optionSelected: {
          id: 0,
          description: 'Veículos',
          key: 'vehicles',
          value: 0,
          iconBefore: 'directions_car',
          selected: true,
          options: [],
        },
        options: [
          {
            id: 0,
            description: 'Veículos',
            key: 'vehicles',
            value: 0,
            iconBefore: 'directions_car',
            selected: true,
            options: [],
          },
          {
            id: 1,
            description: 'Condutores',
            key: 'drivers',
            value: 1,
            iconBefore: 'airline_seat_recline_normal',
            selected: false,
            options: [],
          },
          {
            id: 2,
            description: 'Grupos',
            key: 'groups',
            value: 2,
            iconBefore: 'group_work',
            selected: false,
            options: [],
          },
        ],
      },
      {
        id: 2,
        type: 'Custom',
        title: null,
        icons: {
          isEnable: false,
          before: null,
          after: null,
        },
        placeholder: '',
        enabled: true,
        filteredOptions: [],
        optionSelected: null,
        options: [],
        checkAll: true,
        btnCheckAll: false,
        slot: 'applyOn',
        parentId: 1,
      },
      {
        id: 3,
        type: 'Disclaimer',
        icons: {
          isEnable: false,
          before: null,
          after: null,
        },
      },
      {
        id: 4,
        type: 'Custom',
        slot: 'periodPreset',
        title: 'Filtro de período',
        titleDescription: 'Filtro de período',
        icons: {
          isEnable: false,
          before: null,
          after: null,
        },
        placeholder: '',
        enabled: true,
        childId: 4,
      },
      {
        id: 5,
        type: 'Popup',
        slot: 'periodPicker',
        title: 'Períodos',
        icons: {
          isEnable: false,
          before: null,
          after: 'date_range',
        },
        placeholder: '',
        enabled: true,
        optionSelected: {
          description: 'Calendário semanal',
        },
        value: null,
        parentId: 3,
      },
    ];
    this.dropdownAlertDates = [
      {
        id: 1,
        type: 'Custom',
        title: 'Ativação do alerta',
        titleDescription: 'Ativação do alerta',
        icons: {
          before: null,
          after: 'today',
        },
        field: 'dateStart',
        placeholder: '',
        enabled: true,
        value: new Date(),
        formattedValue: moment().format('DD-MM-YYYY'),
        dateObj: null,
      },
      {
        id: 2,
        type: 'Custom',
        title: 'Expiração do alerta',
        titleDescription: 'Expiração do alerta',
        icons: {
          before: null,
          after: 'event',
        },
        field: 'dateEnd',
        placeholder: '',
        enabled: true,
        value: null,
        formattedValue: '',
        dateObj: null,
      },
    ];
    this.eventsTab = {
      list: [],
      geofences: [],
      options: [],
      optionsLoading: true,
      optionSelected: null,
      valueSelected: 'speed',
      valuesConfig: {
        rpm: {
          minValidation: 3000,
          min: 0,
          max: 10000,
          length: 5,
          value: 3000,
        },
        speed: {
          minValidation: 20,
          min: 0,
          max: 200,
          length: 3,
          value: 20,
        },
        days: {
          minValidation: 10,
          min: 1,
          max: 365,
          length: 3,
          value: 10,
        },
      },
      requiredGeofences: [],
    };
    this.recipientsTab = {
      list: [],
      options: [],
      optionsLoading: true,
      optionSelected: [],
    };
    this.sliderGlobalObj = null;
    this.conditionSliderObj = null;
    this.objAlertOriginal = {
      id: null,
      createdAt: null,
      createdByMe: null,
      creatorName: '',
      description: '',
      applyOnType: null,
      applyOn: [],
      allVehicles: true,
      periodBits: '0,0,0,0,0,0,0',
      dateStart: new Date(),
      dateEnd: null,
      conditions: {
        simultaneous: false,
        items: [],
      },
      recipients: [],
    };
    this.objAlert = Object.clone({}, this.objAlertOriginal);
    this.conditionSelected = null;
    this.validationMessages = [];
    this.dropdownOnReturnItem = null;
    this.driverIdentificationTypes = [];
    $.fn.datepicker.language['pt-BR'] = {
      days: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      daysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      daysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
      monthsShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ],
      today: 'Hoje',
      clear: 'Limpar',
      dateFormat: 'dd/mm/yyyy',
      timeFormat: 'hh:ii',
      firstDay: 0,
    };
  }

  $onInit() {
    super.$onInit();

    this.stateConfig.gridConfig.page = 0;
    this.$ngRedux.dispatch({ type: 'UPDATE_STATE' });

    this.primaryColorSelected = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--primary-color');

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.$timeout(() => {
      this._createGlobalSliderValue();
      this._createConditionSliderValue();
    });

    this.$scope.$on('requestOpenPopupAlert', this.__requestOpenPopupAlert.bind(this));
    this.$scope.$on('showToast', (evt, data) => {
      this.$.querySelector('power-toast#report-toast').toggle(true);
      this.toastObj = { text: data.text };
    });

    this.$scope.$watch(
      () => this.alertCanBeSimultaneous,
      this.__simultaneousTippyObserver.bind(this),
    );
  }

  $onDestroy() {
    this.__appInheritBehavior();
  }

  _emailInfo() {
    return this.newEmailText == null ? this.initialNewEmailText : this.newEmailText;
  }

  _dropdownDescription(option) {
    if (!option.enabled) {
      return 'Aguardando...';
    }
    if (option.optionSelected === null) {
      return 'Todos selecionados';
    }
    if (option.checkAll) {
      return 'Todos selecionados';
    }
    if (option.optSelected) {
      return option.options[option.optSelected].description;
    }
    if (option.optionSelected) {
      if (Array.isArray(option.optionSelected)) {
        if (option.optionSelected.length > 0) {
          return `${option.optionSelected.length} itens selecionados`;
        }
        if (option.optionSelected[0]) {
          return option.optionSelected[0].description;
        }
      } else {
        return option.optionSelected.description;
      }
    }
    if (option.slot == 'periodPreset') {
      if (this.alertPeriodConfig && this.alertPeriodConfig.options) {
        // eslint-disable-next-line no-shadow
        const selectedOption = this.alertPeriodConfig.options.filter(option => option.selected);
        if (selectedOption[0]) {
          return selectedOption[0].description;
        }
        return 'Carregando...';
      }
      return 'Carregando...';
    }
    if (option.loading) {
      return 'Carregando...';
    }
    return 'Selecione...';
  }

  _resizeNewEmailsTextArea() {
    const textAreaElement = this.$.querySelector('#power-add-new-email');
    const characters =
      this.textareaNewEmailValue.length > 45
        ? this.textareaNewEmailValue.length - 1
        : this.textareaNewEmailValue.length;
    let height = parseInt(characters / 45 + 1, 10) * 15;

    if (characters > 405 && textAreaElement.style.height !== '18px') {
      return;
    }

    if (characters > 405) {
      height = parseInt(405 / 45 + 1, 10) * 15;
    }

    if (characters > 45) {
      if (textAreaElement.style.height != `${height}px`) {
        textAreaElement.style.height = `${height}px`;
      }
    } else if (textAreaElement.style.height != '18px') {
      textAreaElement.style.height = '18px';
    }
  }

  _verifyArray(arr1, arr2) {
    if (Array.isArray(arr1) && Array.isArray(arr2) && arr1.length == arr2.length) {
      let booleanReturn = true;
      arr1.forEach((cell, index) => {
        if (Array.isArray(cell) && Array.isArray(arr2[index])) {
          booleanReturn = this._verifyArray(cell, arr2[index]);
        } else if (cell != arr2[index]) {
          booleanReturn = false;
        }
      });
      return booleanReturn;
    }
    return false;
  }

  _createDatePicker() {
    const minDateOriginal = moment().set({ hour: 0, minute: 0, second: 0 })._d;

    this.dropdownAlertDates.forEach(item => {
      $(this.$.querySelector(`#datepicker-${item.id}`)).datepicker({
        language: 'pt-BR',
        classes: `datepicker-report-alerts datepicker-report-alert-${item.id}`,
        datepickerId: item.id,
        toggleSelected: false,
        dateFormat: 'dd-mm-yyyy',
        position: 'top left',
        autoClose: true,
        navTitles: {
          days: 'MM / yyyy',
        },
        minDate: minDateOriginal,
        onSelect: (formattedDate, date, inst) => {
          const objDate = this.dropdownAlertDates
            // eslint-disable-next-line no-shadow
            .filter(item => item.id == inst.opts.datepickerId)
            // eslint-disable-next-line no-shadow
            .map(item => {
              item.formattedValue = formattedDate;
              item.value = date;
              item.showCancel = inst.opts.datepickerId == 2;
              return item;
            })[0];

          if (inst.opts.datepickerId == 1) {
            // eslint-disable-next-line no-shadow
            const datepickerObj2 = this.dropdownAlertDates.filter(item => item.id == 2)[0].dateObj;

            if (datepickerObj2) {
              datepickerObj2.update('minDate', date);

              if (
                datepickerObj2.selectedDates.length > 0 &&
                moment(datepickerObj2.selectedDates[0]).isBefore(date)
              )
                datepickerObj2.selectDate(date);
            }
          }

          this.objAlert[objDate.field] = date;

          if (!this.$scope.$$phase && !this.$rootScope.$$phase) this.$scope.$apply();
        },
      });

      item.dateObj = $(this.$.querySelector(`#datepicker-${item.id}`))
        .datepicker()
        .data('datepicker');
      if (item.value) item.dateObj.selectDate(item.value);
    });
  }

  _createGlobalSliderValue(configObj) {
    configObj = configObj || this.eventsTab.valuesConfig[this.eventsTab.valueSelected];

    this.sliderGlobalObj = new noUiSlider.create(
      this.$.querySelector('.popup-add-required-info .add-required-slider'),
      {
        range: {
          min: configObj.min,
          max: configObj.max,
        },
        connect: [true, false],
        start: configObj.value,
        step: 0,
      },
    );

    this.sliderGlobalObj.on('update', value => {
      const realValue = parseInt(value[0], 10);

      this.eventsTab.valuesConfig[this.eventsTab.valueSelected].value = realValue;
      if (!this.$scope.$$phase && !this.$rootScope.$$phase) this.$scope.$apply();
    });
  }

  _createConditionSliderValue() {
    this.conditionSliderObj = new noUiSlider.create(
      this.$.querySelector('.popup-condition-option-selected .condition-slider'),
      {
        range: {
          min: 10,
          max: 130,
        },
        connect: [true, false],
        start: 10,
        step: 0,
      },
    );

    this.conditionSliderObj.on('update', value => {
      const realValue = parseInt(value[0], 10);

      if (this.conditionSelected) {
        this.conditionSelected.item.value = realValue;
        if (!this.$scope.$$phase && !this.$rootScope.$$phase) this.$scope.$apply();
      }
    });
  }

  _getStateConfig() {
    this.filterServices
      .getPowerFilters(this.stateConfig.screenName, this.stateConfig.gridName)
      .then(getFiltersResult => {
        if (getFiltersResult) {
          const { drilldownMap } = getFiltersResult;
          const filterConfigList = getFiltersResult.filters;
          this.stateConfig.filterConfig = filterConfigList.map(filterConfig =>
            Object.clone({}, filterConfig),
          );
          if (
            this.lastState &&
            drilldownMap.length > 0 &&
            this.lastState.stateConfig.filterConfig
          ) {
            const lastFilterConfigList = this.lastState.stateConfig.filterConfig;
            this.stateConfig.filterConfig = this.stateConfig.filterConfig.map(actualFilter => {
              const drilldown = drilldownMap.filter(
                // eslint-disable-next-line no-shadow
                drilldown => drilldown.toId == actualFilter.id,
              )[0];
              if (drilldown) {
                const previousFilter = lastFilterConfigList.filter(
                  filter => filter.id == drilldown.fromId,
                )[0];
                return { ...actualFilter, condition: Object.clone(previousFilter.condition) };
              }
              return actualFilter;
            });
          }
          if (this.stateConfig.getDataFilters) {
            this.stateConfig.filterConfig = this.stateConfig.filterConfig.map(actualFilter => {
              const fixedFilters = this.stateConfig.getDataFilters.filter(
                condition => condition.id == actualFilter.id,
              )[0];
              return fixedFilters
                ? {
                    ...actualFilter,
                    condition: new FilterCondition(
                      actualFilter.id,
                      actualFilter.field || actualFilter.filters[fixedFilters.activeView].field,
                      fixedFilters.default,
                    ),
                  }
                : actualFilter;
            });
          }
        }
        this.commonServices
          .getToolbarOptions(
            this.stateConfig.screenName,
            this.stateConfig.toolbarName,
            getToolbarResult => {
              const {
                addConfig,
                editConfig,
                viewsConfig,
                exportConfig,
                importConfig,
                actionConfig,
              } = getToolbarResult;
              Object.assign(this.stateConfig.toolbarConfig, {
                addConfig,
                editConfig,
                viewsConfig,
                exportConfig,
                actionConfig,
                importConfig,
              });
              this.$ngRedux.dispatch({ type: 'UPDATE_STATE' });
            },
          )
          .then(() =>
            document
              .querySelector('#app-component')
              .querySelector('power-filter-menu')
              .applyFilters(),
          );
      });
  }

  _requestUnsupportedInfo(id, applyOnType, applyOn, all, tdeId) {
    return this.reportServices.genericRequest('/Alert/ValidateEvent', {
      request: {
        idItem: id,
        applyOnType,
        applyOn: applyOn || [],
        allVehicles: all,
        tdeId,
      },
    });
  }

  _switchNewEmailActiveView() {
    this.newEmailActiveView = !this.newEmailActiveView;

    if (this.newEmailActiveView) {
      this._resizeNewEmailsTextArea();

      setTimeout(() => {
        this.$.querySelector('#power-add-new-email').focus();
      }, 300);
    } else {
      this.newEmailText = null;
      this.newEmailSuccessIcon = false;
      this.textareaNewEmailValue = '';
    }
  }

  _filterVisibleOptions(item, searchText) {
    if (!searchText) {
      item.filteredOptions = item.options;
      item.btnCheckAll = !item.options.find(option => !option.selected);
      return;
    }

    const filterSearchs = searchText.split(/[;,]/);
    const filteredOptions = [];

    item.options.forEach(option => {
      filterSearchs.forEach(itemSearch => {
        if (!filteredOptions.find(matchedItem => matchedItem.description == option.description)) {
          if (typeof option.isActive === 'boolean') {
            const match =
              option.description.toUpperCase().includes(itemSearch.trim().toUpperCase()) &&
              option.isActive;

            if (match) {
              filteredOptions.push(option);
            }
          } else {
            const match = option.description
              .toUpperCase()
              .includes(itemSearch.trim().toUpperCase());

            if (match) {
              filteredOptions.push(option);
            }
          }
        }
      });
    });

    item.filteredOptions = filteredOptions;

    item.btnCheckAll =
      (item.filteredOptions.length > 0 && !item.filteredOptions.find(option => !option.selected)) ||
      (item.filteredOptions.length === item.options.length &&
        !item.options.find(option => !option.selected));
  }

  _addNewMail() {
    const regexInv = new RegExp(
      /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.{1})+[a-z0-9](?:\.?[a-z0-9-]*[a-z0-9])$/,
    );
    const emailsValue = this.textareaNewEmailValue
      .replace(/[ ;]+/g, ',')
      .split(/[ ,]+/)
      .map(item => item.trim())
      .filter(item => item)
      .filter((item, index, self) => item && self.indexOf(item) == index);

    this.textareaNewEmailValue = emailsValue.join(', ');

    const allEmails = emailsValue.reduce(
      (acc, email) => {
        const splittedMail = email.split(/(@)/);
        if (
          !email.includes('@') ||
          !regexInv.test(email) ||
          (!splittedMail[2] && splittedMail[2] == '')
        ) {
          acc.invalidEmails = acc.invalidEmails.concat(email);
        } else {
          acc.validEmails = acc.validEmails.concat(email);
        }
        return acc;
      },
      { validEmails: [], invalidEmails: [] },
    );

    if (allEmails.invalidEmails.length > 0) {
      this.hasInvalidEmails = true;
      this.textareaNewEmailValue = allEmails.invalidEmails.join().replace(',', ', ');
    } else {
      this.hasInvalidEmails = false;
    }

    this._resizeNewEmailsTextArea();

    if (allEmails.validEmails.length > 0) {
      this.reportServices
        .genericRequest('/User/CreateUserEmails', {
          request: {
            emails: allEmails.validEmails,
          },
        })
        .then(success => {
          if (!success.data || success.data.hasError) {
            this.toastObj.text = 'Ops! Falha ao salvar, tente novamente.';
            this.$.querySelector('power-toast#report-toast').toggle(true);
            this.newEmailSuccessIcon = false;
            return; // error
          }
          if (allEmails.invalidEmails.length > 0) {
            this.toastObj.text =
              'Emails válidos cadastrados; Há alguns emails inválidos aguardando correção e re-envio';
          } else {
            this.toastObj.text = 'Emails cadastrados';
          }
          this.$.querySelector('power-toast#report-toast').toggle(true);

          // need refactor it and transform it into a function to prevent code duplicate
          // 3. Load combo Recipientes (aba Recipientes)
          this.reportServices
            .genericRequest(
              `/${this.stateConfig.isAdm ? 'AlertAdm' : 'Alert'}/GetUserRecipients`,
              {},
            )
            .then(
              // eslint-disable-next-line no-shadow
              success => {
                this.recipientsTab.optionsLoading = false;
                if (success.data && success.data.hasError) return;
                if (success.data.data && success.data.data.length > 0) {
                  const dataProcessed = [];
                  success.data.data.forEach(item => {
                    dataProcessed.push(
                      new RecipientsOptions(item.id, item.lgnNome, item.id, item.prfIcone, item),
                    );
                  });
                  this.recipientsTab.options = dataProcessed;
                  const emailsCount = allEmails.validEmails.length;

                  if (allEmails.invalidEmails.length == 0) {
                    this.textareaNewEmailValue = '';
                    this.newEmailText = `${
                      emailsCount > 1 ? 'Foram incluídos' : 'Foi incluído'
                    } <b>${emailsCount} ${
                      emailsCount > 1 ? 'emails' : 'email'
                    }</b> na listagem de destinatários.`;
                  } else if (allEmails.invalidEmails.length > 0) {
                    this.newEmailText = `${
                      emailsCount > 1 ? 'Foram incluídos' : 'Foi incluído'
                    } <b>${emailsCount} ${
                      emailsCount > 1 ? 'emails' : 'email'
                    }</b> na listagem de destinatários. Os endereços acima não puderam ser cadastrados, por favor corrija os e-mails e tente novamente.`;
                  }

                  this.newEmailSuccessIcon = true;
                }
              },
              () => {
                this.recipientsTab.optionsLoading = false;
              },
            );
          // success
        });
    } else {
      this.toastObj.text =
        'Não há emails válidos a serem cadastrados, por favor verifique o campo de email e tente novamente';
      this.$.querySelector('power-toast#report-toast').toggle(true);
    }
  }

  _loadTabConditions(popupDataset) {
    this.reportServices
      .genericRequest(`/${this.stateConfig.isAdm ? 'AlertAdm' : 'Alert'}/GetGeoFences`, {})
      .then(success => {
        if (success.data && success.data.hasError) return;
        if (success.data.data && success.data.data.length > 0) {
          this.eventsTab.geofences = success.data.data;
        }
        if (popupDataset) {
          this._loadSystemAlerts(popupDataset);
        }
      });
  }

  _loadSystemAlerts(popupDataset) {
    this.eventsTab.optionsLoading = true;
    this.reportServices
      .genericRequest(`${this.stateConfig.isAdm ? 'AlertAdm' : 'Alert'}/GetSystemAlerts`, {
        request: {
          alertType:
            this.dropdownOptionsAlert[0].options[this.dropdownOptionsAlert[0].optionSelected.id]
              .description == 'Grupos'
              ? 0
              : this.dropdownOptionsAlert[0].options[this.dropdownOptionsAlert[0].optionSelected.id]
                  .value,
        },
      })
      .then(
        // eslint-disable-next-line no-shadow
        success => {
          this.eventsTab.optionsLoading = false;
          if (success.data && success.data.hasError) return;
          if (success.data.data && success.data.data.length > 0) {
            this.eventsTab.options = success.data.data;

            popupDataset.conditions.items.forEach((condition, idx) => {
              const itemEvent = this.eventsTab.options.filter(item => item.id == condition.id)[0];

              let unsupportedInfo = null;

              const geofences = [];

              this.eventsTab.geofences.forEach(item => {
                item.selected = false;
              });
              condition.geoFences.forEach(geo => {
                const geoFind = Object.clone(
                  {},
                  this.eventsTab.geofences.filter(item => item.id == geo)[0],
                );

                if (geoFind) {
                  geoFind.selected = true;
                  geofences.push(geoFind);
                }
              });

              if (itemEvent) {
                this._requestUnsupportedInfo(
                  itemEvent.id,
                  this.dropdownOptionsAlert[0].options[
                    this.dropdownOptionsAlert[0].optionSelected.id
                  ].value,
                  this.dropdownOptionsAlert[1].optionSelected
                    ? this.dropdownOptionsAlert[1].optionSelected.map(el => el.id)
                    : [],
                  this.dropdownOptionsAlert[1].checkAll,
                  itemEvent.tdeId,
                )
                  .then(success2 => {
                    if (success2.data && success2.data.hasError) {
                      return;
                    }
                    unsupportedInfo = new UnsupportedInfo(success2.data.data);

                    this.objAlert.conditions.items.push(
                      new EventItem(
                        this,
                        idx,
                        condition.idItem,
                        itemEvent.id,
                        itemEvent.icon || '',
                        itemEvent.description,
                        unsupportedInfo,
                        itemEvent.typeId,
                        itemEvent.tdeId,
                        itemEvent.isSimultaneous ? this.objAlert.conditions.simultaneous : false,
                        itemEvent.complementId,
                        itemEvent.complementDescription,
                        condition.value,
                        geofences,
                        itemEvent.tooltip,
                        itemEvent.hasGeoFences,
                        itemEvent.isSimultaneous,
                      ),
                    );
                  })
                  .finally(() => {
                    if (this.objAlert.conditions.items.every(item => item.isSimultaneous)) {
                      this.alertCanBeSimultaneous = true;
                    } else {
                      this.alertCanBeSimultaneous = false;
                      this.objAlert.conditions.simultaneous = false;
                    }
                  });
              }
            });
          }
        },
        () => {
          this.eventsTab.optionsLoading = false;
        },
      );
  }

  __convertDateToJson(date) {
    return new Date(
      new Date(date).setUTCHours(date.getUTCHours() - date.getTimezoneOffset() / 60),
    ).toJSON();
  }

  __createInitialComponentsAndRequests(popupStatus, popupDataset) {
    if (popupDataset) {
      const itemSelected = this.dropdownOptionsAlert[0].options
        .filter(option => option.id == popupDataset.applyOnType)
        .map((item, index) => ({
          item,
          index,
        }))[0];
      this.dropdownOptionsAlert[0].optionSelected =
        itemSelected.item || this.dropdownOptionsAlert[0].options[0];
      this.dropdownOptionsAlert[0].optSelected = itemSelected.index || 0;
    } else {
      const powerPeriod = this.$.querySelector('power-period');
      const { optionSelected } = this.alertPeriodConfig;

      if (optionSelected) {
        this.alertPeriodConfig.options.forEach(option => {
          option.selected = option.id === optionSelected.id;
        });
        powerPeriod.setValue(optionSelected.value);
      } else {
        powerPeriod.clearValue();
      }
    }

    // 1. Load combos, que tenham method e estejam enabled (aba Regras)
    this.dropdownOptionsAlert.forEach(item => {
      if (item.enabled && item.method) {
        this.reportServices
          .genericRequest(
            `/${this.stateConfig.isAdm ? item.methodAdm : item.method}`,
            item.methodParams || {},
          )
          .then(success => {
            if (success.data && success.data.hasError) return;
            if (success.data.data && success.data.data[item.optionSelected.key].length > 0) {
              item.optionSelected.options = success.data.data[item.optionSelected.key];

              item.options.forEach(itemOpt => {
                itemOpt.options = success.data.data[itemOpt.key];
              });

              item.options = item.options.map(option => ({
                ...option,
                options: option.options.filter(subOption => {
                  if (
                    (option.key === 'vehicles' || option.key === 'groups') &&
                    typeof subOption.isActive === 'boolean'
                  ) {
                    return subOption.isActive;
                  }

                  return true;
                }),
              }));

              if (popupDataset && item.slot == 'applyOnType') {
                item.options.forEach(itemOpt => {
                  itemOpt.selected = false;
                });
                [item.optionSelected] = item.options
                  .filter(option => option.value == popupDataset.applyOnType)
                  .map((mapItem, index) => {
                    item.optSelected = index;
                    mapItem.selected = true;
                    return mapItem;
                  });
              }
              this.dropdownOptionsAlert
                .filter(dropdownItem => dropdownItem.parentId == item.id)
                .map(mappedItem => {
                  mappedItem.enabled = true;
                  mappedItem.options = success.data.data[item.optionSelected.key];

                  if (mappedItem.options.every(data => typeof data.isActive === 'boolean')) {
                    // eslint-disable-next-line no-shadow
                    mappedItem.options = mappedItem.options.filter(item => item.isActive == true);
                  }

                  mappedItem.filteredOptions = mappedItem.options;

                  if (popupDataset && mappedItem.slot == 'applyOn') {
                    const itemsSelected = [];
                    popupDataset.applyOn.forEach(itemId => {
                      const findId = mappedItem.options
                        .filter(options => options.id == itemId)
                        // eslint-disable-next-line no-shadow
                        .map(item => {
                          item.selected = true;

                          return item;
                        })[0];
                      if (findId) itemsSelected.push(findId);
                    });
                    mappedItem.optionSelected = itemsSelected;
                    mappedItem.checkAll = popupDataset.allVehicles;
                    mappedItem.btnCheckAll =
                      popupDataset.applyOn.length === mappedItem.options.length;
                    mappedItem.options = mappedItem.options.sort(
                      (a, b) => !a.selected - !b.selected,
                    );
                    mappedItem.filteredOptions = mappedItem.options;
                  }
                  return item;
                });
            }
          });
      }
    });

    // 2. Load combo Condições (aba Condições)
    this._loadTabConditions(popupDataset);

    // 3. Load combo Recipientes (aba Recipientes)
    this.reportServices
      .genericRequest(`/${this.stateConfig.isAdm ? 'AlertAdm' : 'Alert'}/GetUserRecipients`, {})
      .then(
        success => {
          this.recipientsTab.optionsLoading = false;
          if (success.data && success.data.hasError) return;
          if (success.data.data && success.data.data.length > 0) {
            const dataProcessed = [];
            success.data.data.forEach(item => {
              dataProcessed.push(
                new RecipientsOptions(item.id, item.lgnNome, item.id, item.prfIcone, item),
              );
            });
            this.recipientsTab.options = dataProcessed;

            if (popupDataset) {
              popupDataset.recipients.forEach(item => {
                const recipientFind = this.recipientsTab.options.filter(
                  option => option.id == item,
                )[0];

                if (recipientFind) {
                  this.objAlert.recipients.push(
                    new RecipientsItem(this.objAlert.recipients.length, recipientFind.originalObj),
                  );
                }
              });
            }
          }
        },
        () => {
          this.recipientsTab.optionsLoading = false;
        },
      );

    // this.$timeout(() => {
    this.statusPopupAlert = popupStatus || 1;
    // }, 2000);

    this.$timeout(() => {
      this._createDatePicker();
      this.dropdownAlertDates.forEach(item => {
        if (item.value) {
          item.value = new Date();
          item.dateObj.selectDate(item.value);
        }
      });
    });
  }

  __resetAll() {
    this.statusPopupAlert = 0;
    this.popupAlertTabSelected = 1;

    this.objAlert = Object.clone({}, this.objAlertOriginal);

    // 1. combos de 'Aplicar alerta em'
    this.dropdownOptionsAlert[0].options.forEach(item => {
      item.selected = false;
    });
    this.dropdownOptionsAlert[0].options[0].selected = true;
    [this.dropdownOptionsAlert[0].optionSelected] = this.dropdownOptionsAlert[0].options;
    this.dropdownOptionsAlert[0].optSelected = 0;

    this.dropdownOptionsAlert[1].optionSelected = null;
    this.dropdownOptionsAlert[1].options = [];
    this.dropdownOptionsAlert[1].filteredOptions = [];
    this.dropdownOptionsAlert[1].checkAll = true;
    this.dropdownOptionsAlert[1].btnCheckAll = false;

    // 2. combos de Período

    // 3. datas de ativação e desativação
    this.dropdownAlertDates.forEach(item => {
      if (item.value) {
        item.value = new Date();
        item.dateObj.selectDate(item.value);
      }
      if (item.field == 'dateEnd') {
        item.value = null;
        item.dateObj.clear();
      }
    });
  }

  __validateBeforeSave() {
    this.validationMessages = [];
    if (!this.objAlert.description || this.objAlert.description == '')
      this.validationMessages.push(
        this.$sce.trustAsHtml(
          'Aba: <b>REGRAS</b>. Campo: <b>"Nome do Alerta"</b> não pode ser vazio.',
        ),
      );

    if (this.dropdownOptionsAlert[1].options.length === 0)
      this.validationMessages.push(
        this.$sce.trustAsHtml(`Aba: <b>REGRAS</b>. Campo: <b>"Aplicar alerta em"</b>.
				O item selecionado (${
          this.dropdownOptionsAlert[0].optionSelected.description
        }) não possui nenhum valor.
				Necessário ter ao menos um ${
          this.dropdownOptionsAlert[0].optionSelected.id == 0 ? 'veículo' : 'condutor'
        } na base.`),
      );

    if (this.dropdownAlertDates[0].dateObj.selectedDates.length > 0)
      this.objAlert.dateStart = moment(this.dropdownAlertDates[0].dateObj.selectedDates[0])._d;

    if (this.dropdownAlertDates[1].dateObj.selectedDates.length > 0)
      this.objAlert.dateEnd = moment(this.dropdownAlertDates[1].dateObj.selectedDates[0])._d;

    if (this.objAlert.dateStart) {
      if (!moment(this.objAlert.dateStart).isValid())
        this.validationMessages.push(
          this.$sce.trustAsHtml(
            'Aba: <b>REGRAS</b>. Campo: <b>"Ativação do alerta"</b>, está incorreto.',
          ),
        );
    } else
      this.validationMessages.push(
        this.$sce.trustAsHtml(
          'Aba: <b>REGRAS</b>. Campo: <b>"Ativação do alerta"</b> está incorreto.',
        ),
      );

    if (this.objAlert.dateEnd && !moment(this.objAlert.dateEnd).isValid()) {
      this.validationMessages.push(
        this.$sce.trustAsHtml(
          'Aba: <b>REGRAS</b>. Campo: <b>"Expiração do alerta"</b>, está incorreto.',
        ),
      );
    }

    if (this.objAlert.conditions.items.length === 0)
      this.validationMessages.push(
        this.$sce.trustAsHtml('Aba: <b>CONDIÇÕES</b>. Obrigatório pelo menos uma condição.'),
      );

    if (this.objAlert.recipients.length === 0)
      this.validationMessages.push(
        this.$sce.trustAsHtml('Aba: <b>DESTINATÁRIOS</b>. Obrigatório pelo menos um destinatário.'),
      );

    if (this.validationMessages.length > 0) {
      this.$.querySelector('#popup-validation-messages').toggle();
      return false;
    }

    return true;
  }

  __methodCreateAlert() {
    const objRequest = {
      description: this.objAlert.description,
      applyOnType:
        this.dropdownOptionsAlert[0].options[this.dropdownOptionsAlert[0].optionSelected.id].value,
      applyOn: this.dropdownOptionsAlert[1].optionSelected
        ? this.dropdownOptionsAlert[1].optionSelected.map(el => el.id)
        : [],
      allVehicles: this.dropdownOptionsAlert[1].checkAll,
      periodBits: this.$.querySelector('power-period').getValue().join(),
      dateStart: this.__convertDateToJson(this.objAlert.dateStart),
      dateEnd: this.objAlert.dateEnd ? this.__convertDateToJson(this.objAlert.dateEnd) : null,
      conditions: {
        simultaneous: this.objAlert.conditions.simultaneous,
        items: this.objAlert.conditions.items.map(element =>
          Object({
            id: element.idItem,
            idItem: null,
            value: element.value,
            geoFences: element.geofence.map(geo => geo.id),
          }),
        ),
      },
      recipients: this.objAlert.recipients.map(element => element.item.id),
    };

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.reportServices
      .genericRequest(`/${this.stateConfig.isAdm ? 'AlertAdm' : 'Alert'}/CreateAlert`, {
        request: objRequest,
      })
      .then(
        success => {
          if ((success.data && success.data.hasError) || !success.data.data) {
            this.toastObj.text = 'Ops! Falha ao salvar, tente novamente.';
            this.$.querySelector('power-toast#report-toast').toggle(true);
            return;
          }

          if (success.data.data) {
            this.$.querySelector('#popup-control-alert').toggle();
            this.__resetAll();
            this.toastObj.text = 'Salvo com sucesso!';
            this.$.querySelector('power-toast#report-toast').toggle(true);
            this.$scope.$broadcast('getDataset');
          }
        },
        () => {
          this.toastObj.text = 'Ops! Falha ao salvar, tente novamente.';
          this.$.querySelector('power-toast#report-toast').toggle(true);
        },
      )
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  __methodUpdateAlert() {
    const applyOnData = this.dropdownOptionsAlert[1].options
      .filter(item => item.selected)
      .map(item => item.id);
    const objRequest = {
      id: this.objAlert.id,
      description: this.objAlert.description,
      applyOnType:
        this.dropdownOptionsAlert[0].options[this.dropdownOptionsAlert[0].optionSelected.id].value,
      applyOn: applyOnData.length > 0 ? applyOnData : [],
      allVehicles:
        this.dropdownOptionsAlert[1].optionSelected === null
          ? true
          : this.dropdownOptionsAlert[1].checkAll,
      periodBits: this.$.querySelector('power-period').getValue().join(),
      dateStart: this.__convertDateToJson(this.objAlert.dateStart),
      dateEnd: this.objAlert.dateEnd ? this.__convertDateToJson(this.objAlert.dateEnd) : null,
      conditions: {
        simultaneous: this.objAlert.conditions.simultaneous,
        items: this.objAlert.conditions.items.map(element =>
          Object({
            id: element.idItem,
            idItem: element.id,
            value: element.value,
            geoFences: element.geofence.map(geo => geo.id),
          }),
        ),
      },
      recipients: this.objAlert.recipients.map(element => element.item.id),
    };
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.reportServices
      .genericRequest(`/${this.stateConfig.isAdm ? 'AlertAdm' : 'Alert'}/UpdateAlert`, {
        request: objRequest,
      })
      .then(
        success => {
          if ((success.data && success.data.hasError) || !success.data.data) {
            this.toastObj.text = 'Ops! Falha ao salvar, tente novamente.';
            this.$.querySelector('power-toast#report-toast').toggle(true);
            return;
          }
          if (success.data.data) {
            this.$.querySelector('#popup-control-alert').toggle();
            this.__resetAll();
            this.toastObj.text = 'Salvo com sucesso!';
            this.$.querySelector('power-toast#report-toast').toggle(true);
            this.$scope.$broadcast('getDataset');
          }
        },
        () => {
          this.toastObj.text = 'Ops! Falha ao salvar, tente novamente.';
          this.$.querySelector('power-toast#report-toast').toggle(true);
        },
      )
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  __methodDeleteAlert() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.reportServices
      .genericRequest(`/${this.stateConfig.isAdm ? 'AlertAdm' : 'Alert'}/UpdateAlertStatus`, {
        id: this.objAlert.id,
        deleteInativateAlert: 2,
      })
      .then(
        success => {
          if ((success.data && success.data.hasError) || !success.data.data) {
            this.toastObj.text = 'Ops! Falha ao deletar, tente novamente.';
            this.$.querySelector('power-toast#report-toast').toggle(true);
            return;
          }

          if (success.data.data) {
            this.$.querySelector('#popup-control-alert').toggle();
            this.__resetAll();
            this.toastObj.text = 'Alerta deletado!';
            this.$.querySelector('power-toast#report-toast').toggle(true);
            this.$scope.$broadcast('getDataset');
          }
        },
        () => {
          // console.error(error);
          this.toastObj.text = 'Ops! Falha ao deletar, tente novamente.';
          this.$.querySelector('power-toast#report-toast').toggle(true);
        },
      )
      .finally(
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  __requestOpenPopupAlert(evt, evtParams) {
    this.statusPopupAlert = 0;
    this.$.querySelector('#popup-control-alert').toggle();
    this.reportServices
      .genericRequest(`/${this.stateConfig.isAdm ? 'AlertAdm' : 'Alert'}/GetAlertDetails`, {
        id: evtParams.id,
      })
      .then(
        success => {
          if (success.data && success.data.hasError) {
            this.statusPopupAlert = 3;
            return;
          }

          if (success.data.data)
            this.$timeout(() => {
              this.__createInitialComponentsAndRequests(2, success.data.data);
              this.objAlert.id = success.data.data.id;
              this.objAlert.description = success.data.data.description;
              this.objAlert.createdByMe = success.data.data.createdByMe;
              this.objAlert.createdAt = success.data.data.createdAt;
              this.objAlert.creatorName = success.data.data.creatorName;
              this.objAlert.conditions.simultaneous = success.data.data.conditions.simultaneous;

              // Muda os valores no calendário de período e preset
              const periodBitsArray = success.data.data.periodBits?.split(',');

              if (periodBitsArray) {
                const powerPeriod = this.$.querySelector('power-period');
                powerPeriod.setValue(periodBitsArray);

                this.alertPeriodConfig.options.forEach(option => {
                  option.selected =
                    (Array.isArray(option.value) ? option.value : [option.value]).join() ===
                    success.data.data.periodBits;
                });

                if (this.alertPeriodConfig.options.every(option => !option.selected)) {
                  this.alertPeriodConfig.options.forEach(option => {
                    option.selected = option.value === null;
                  });
                }
              }

              // Muda os valores dos campos de Data
              this.$timeout(() => {
                this.dropdownAlertDates.forEach(item => {
                  if (item.dateObj) {
                    if (item.field == 'dateStart') {
                      item.value = moment(success.data.data.dateStart)._d;
                      item.dateObj.update({
                        minDate: moment(item.value).isAfter(moment()._d, 'day')
                          ? new Date()
                          : item.value,
                      });
                    } else if (!success.data.data.notExpire)
                      item.value = moment(success.data.data.dateEnd)._d;

                    item.dateObj.selectDate(item.value);
                  }
                });
              });
            }, 300);
          else {
            this.statusPopupAlert = 3;
          }
        },
        () => {
          // console.error(error);
          this.statusPopupAlert = 3;
        },
      );
  }

  __toggleIdentificationTypes() {
    this.$.querySelector('#popup-identification-types').toggle();
  }

  __canShowDriverDisclaimer() {
    const [applyOnTypeField] = this.dropdownOptionsAlert.filter(option => option.id == 1);
    return applyOnTypeField.optionSelected.id == 1;
  }

  evtClickBtnPopup(param) {
    if (param == 'cancel') {
      this.$.querySelector('#popup-control-alert').toggle();
      this.$timeout(() => {
        this.__resetAll();
      }, 600);
    } else if (param == 'add') {
      if (this.__validateBeforeSave()) this.__methodCreateAlert();
    } else if (param == 'update') {
      if (this.__validateBeforeSave()) this.__methodUpdateAlert();
    } else this.$.querySelector('#popup-confirm-delete-alert').toggle();
  }

  evtClickAddAlert() {
    this.$.querySelector('#popup-control-alert').toggle();
    this.__createInitialComponentsAndRequests();
  }

  evtClickPopupAlertTab(tabNumber) {
    // 1. Regras, 2. Condições, 3. Recipientes
    this.popupAlertTabSelected = tabNumber;
    this.$.querySelector('.body-content').setAttribute('tab', tabNumber);

    const conditionsLength = this.objAlert.conditions.items.length;

    if (tabNumber == 2) {
      const alertType =
        this.dropdownOptionsAlert[0].options[this.dropdownOptionsAlert[0].optionSelected.id]
          .description == 'Grupos'
          ? 0
          : this.dropdownOptionsAlert[0].options[this.dropdownOptionsAlert[0].optionSelected.id]
              .value;
      this.objAlert.conditions.items = this.objAlert.conditions.items.sort((a, b) => a - b);
      this.eventsTab.optionsLoading = true;
      this.reportServices
        .genericRequest(`/${this.stateConfig.isAdm ? 'AlertAdm' : 'Alert'}/GetSystemAlerts`, {
          request: {
            alertType,
          },
        })
        .then(
          success => {
            this.eventsTab.optionsLoading = false;
            if (success.data && success.data.hasError) return;
            if (success.data.data && success.data.data.length > 0) {
              this.eventsTab.options = success.data.data;
            }
          },
          () => {
            this.eventsTab.optionsLoading = false;
          },
        );
    }

    if (tabNumber == 2 && this.objAlert.conditions.items.length > 0) {
      this.bodyBlocked = true;
      let countFinish = 0;

      this.objAlert.conditions.items.forEach(condition => {
        this._requestUnsupportedInfo(
          condition.idItem,
          this.dropdownOptionsAlert[0].options[this.dropdownOptionsAlert[0].optionSelected.id]
            .value,
          this.dropdownOptionsAlert[1].optionSelected
            ? this.dropdownOptionsAlert[1].optionSelected.map(el => el.id)
            : [],
          this.dropdownOptionsAlert[1].checkAll,
          condition.tdeId,
        ).then(success => {
          if (success.data && success.data.hasError) {
            // message error
          } else {
            condition.unsupportedInfo = new UnsupportedInfo(success.data.data);
            countFinish += 1;

            if (countFinish === conditionsLength) this.bodyBlocked = false;
          }
        });
      });
    }
  }

  evtClickEnableDropdown(option) {
    const enabled = !option.icons.enabled;
    option.icons.enabled = enabled;
    option.enabled = enabled;

    if (option.childId) {
      // Habilita o elemento dependente
      // eslint-disable-next-line no-unused-expressions
      this.dropdownOptionsAlert
        .filter(item => item.id == option.childId)
        .map(item => {
          item.enabled = option.enabled;

          return item;
        })[0];
    }
  }

  evtDropdownOnReturn(itemReturn, option) {
    if (itemReturn.value == option.optionSelected.value) return;

    this.dropdownOnReturnItem = { ...option.optionSelected };
    option.options.forEach(opt => {
      opt.selected = false;
    });

    option.optionSelected = itemReturn;
    itemReturn.selected = true;

    if (option.slot == 'applyOnType' && this.objAlert.conditions.items.length > 0)
      this.$.querySelector('#popup-confirm-clear-conditions').toggle();

    if (option.childId) {
      const [childObj] = this.dropdownOptionsAlert.filter(item => item.id == option.childId);

      itemReturn.options.forEach(opt => {
        opt.selected = false;
      });

      childObj.optionSelected = null;
      childObj.options = itemReturn.options;
      childObj.filteredOptions = itemReturn.options;
    }
  }

  evtClickCheckAllApplyOn(option) {
    option.btnCheckAll = !option.btnCheckAll;

    option.filteredOptions.forEach(opt => {
      opt.selected = option.btnCheckAll;
    });

    option.checkAll = option.filteredOptions.length === option.options.length;
    option.optionSelected = option.options.filter(item => item.selected);

    if (option.optionSelected.length == 0) option.optionSelected = null;
  }

  evtClickSelectItemApplyOn(item, option) {
    item.selected = !item.selected;

    option.optionSelected = option.options.filter(itemOption => itemOption.selected);

    if (option.optionSelected.length == 0) {
      option.optionSelected = null;
      option.checkAll = true;
    } else if (option.optionSelected.length == option.options.length)
      // eslint-disable-next-line no-multi-assign
      option.checkAll = option.btnCheckAll = true;
    // eslint-disable-next-line no-multi-assign
    else option.checkAll = option.btnCheckAll = false;
  }

  // Eventos do Slider que está no popup de informação obrigatória
  evtChangeInputGlobalSliderValue(value) {
    this.sliderGlobalObj.set(value);
  }

  // Eventos dos combos de períodos
  evtClickPeriodPreset(option) {
    const powerPeriod = this.$.querySelector('power-period');
    powerPeriod.setValue(option.value || []);

    this.alertPeriodConfig.options.forEach(item => {
      item.selected = item.id === option.id;
    });
  }

  // Eventos da Grid de condições e destinatários
  evtClickDeleteItem(item, tab, itemIndex) {
    const itemSelected = { ...item };
    this.deleteItemInfo = { item: itemSelected, tab, index: itemIndex };

    this.$.querySelector('#popup-confirm-delete-item').toggle();
  }

  evtClickConfirmDeleteItem(action) {
    if (action) {
      if (this.deleteItemInfo.tab == 'conditions') {
        this.objAlert.conditions.items.splice(this.deleteItemInfo.index, 1);

        if (this.objAlert.conditions.items.every(item => item.isSimultaneous)) {
          this.alertCanBeSimultaneous = true;
        } else {
          this.alertCanBeSimultaneous = false;
          this.objAlert.conditions.simultaneous = false;
        }
      } else if (this.deleteItemInfo.tab == 'recipients')
        this.objAlert.recipients.splice(this.deleteItemInfo.index, 1);
    }

    this.$.querySelector('#popup-confirm-delete-item').toggle();
    this.deleteItemInfo = null;
  }

  // Eventos do popup de deletar Alerta
  evtClickConfirmDeleteAlert(action) {
    if (action) {
      this.__methodDeleteAlert();
    }

    this.$.querySelector('#popup-confirm-delete-alert').toggle();
  }

  // Eventos do popup de informação obrigatória
  evtClickCancelAddRequiredInfo() {
    this.$.querySelector('#popup-add-required-info').toggle();
    this.bodyBlocked = false;

    this.$timeout(() => {
      this.eventsTab.options
        .filter(item => item.id == this.eventsTab.optionSelected.id)
        .map(item => {
          item.selected = false;

          return item;
        });
      this.eventsTab.optionSelected = null;
    }, 300);
  }

  evtClickAddRequiredInfo() {
    let unsupportedInfo = null;
    const { value } = this.eventsTab.valuesConfig[this.eventsTab.valueSelected];

    const geofence = Object.clone(
      [],
      this.eventsTab.geofences.filter(item => item.selected),
    );

    this.bodyBlocked = true;
    this._requestUnsupportedInfo(
      this.eventsTab.optionSelected.id,
      this.dropdownOptionsAlert[0].options[this.dropdownOptionsAlert[0].optionSelected.id].value,
      this.dropdownOptionsAlert[1].optionSelected
        ? this.dropdownOptionsAlert[1].optionSelected.map(el => el.id)
        : [],
      this.dropdownOptionsAlert[1].checkAll,
      this.eventsTab.optionSelected.tdeId,
    ).then(
      success => {
        if (success.data && success.data.hasError) {
          // message error
          this.bodyBlocked = false;
        } else {
          this.bodyBlocked = false;
          unsupportedInfo = new UnsupportedInfo(success.data.data);

          this.objAlert.conditions.items.push(
            new EventItem(
              this,
              this.objAlert.conditions.items.length,
              null,
              this.eventsTab.optionSelected.id,
              this.eventsTab.optionSelected.icon || '',
              this.eventsTab.optionSelected.description,
              unsupportedInfo,
              this.eventsTab.optionSelected.typeId,
              this.eventsTab.optionSelected.tdeId,
              this.eventsTab.optionSelected.isSimultaneous
                ? this.objAlert.conditions.simultaneous
                : false,
              this.eventsTab.optionSelected.complementId,
              this.eventsTab.optionSelected.complementDescription,
              this.eventsTab.optionSelected.typeId == 4 ? value : null,
              geofence,
              this.eventsTab.optionSelected.tooltip,
              this.eventsTab.optionSelected.hasGeoFences,
              this.eventsTab.optionSelected.isSimultaneous,
            ),
          );
        }

        if (this.objAlert.conditions.items.every(item => item.isSimultaneous)) {
          this.alertCanBeSimultaneous = true;
        } else {
          this.alertCanBeSimultaneous = false;
          this.objAlert.conditions.simultaneous = false;
        }

        this.$.querySelector('#popup-add-required-info').toggle();
        this.$timeout(() => {
          this.eventsTab.options
            .filter(option => option.id == this.eventsTab.optionSelected.id)
            .map(item => {
              item.selected = false;

              return item;
            });
          this.eventsTab.optionSelected = null;
        }, 300);
      },
      () => {
        this.bodyBlocked = false;
      },
    );
  }

  // Eventos da aba condições
  evtClickSimultaneousCondition() {
    if (this.alertCanBeSimultaneous) {
      this.objAlert.conditions.simultaneous = !this.objAlert.conditions.simultaneous;
    }
  }

  evtClickAddEvent() {
    if (!this.eventsTab.optionSelected) return;

    // 1. Consulta equipamentos incompatíveis com base na seleção de veículos/condutores na aba Regras
    let unsupportedInfo = null;

    const value = null;

    const geofence = null;

    const getSelectedOption = complementId => {
      let selectedTab;

      switch (complementId) {
        case 1:
          selectedTab = 'speed';
          break;

        case 2:
          selectedTab = 'rpm';
          break;

        case 11:
          selectedTab = 'days';
          break;

        default:
          break;
      }

      return selectedTab;
    };

    // 2. Verifica se o evento é de valor ou área de controle
    if (this.eventsTab.optionSelected.typeId != 2) {
      if (this.eventsTab.optionSelected.typeId == 4) {
        this.eventsTab.valueSelected = getSelectedOption(
          this.eventsTab.optionSelected.complementId,
        );

        this.sliderGlobalObj.updateOptions({
          range: {
            min: this.eventsTab.valuesConfig[this.eventsTab.valueSelected].min,
            max: this.eventsTab.valuesConfig[this.eventsTab.valueSelected].max,
          },
          start:
            this.eventsTab.valuesConfig[this.eventsTab.valueSelected].value ||
            this.eventsTab.valuesConfig[this.eventsTab.valueSelected].min,
        });
      }

      this.eventsTab.geofences.forEach(item => {
        item.selected = false;
      });

      this.$.querySelector('#popup-add-required-info').toggle();

      return;
    }

    this.bodyBlocked = true;
    this._requestUnsupportedInfo(
      this.eventsTab.optionSelected.id,
      this.dropdownOptionsAlert[0].options[this.dropdownOptionsAlert[0].optionSelected.id].value,
      this.dropdownOptionsAlert[1].optionSelected
        ? this.dropdownOptionsAlert[1].optionSelected.map(el => el.id)
        : [],
      this.dropdownOptionsAlert[1].checkAll,
      this.eventsTab.optionSelected.tdeId,
    ).then(
      success => {
        if (success.data && success.data.hasError) {
          // message error
          this.bodyBlocked = false;
        } else {
          this.bodyBlocked = false;
          unsupportedInfo = new UnsupportedInfo(success.data.data);

          this.objAlert.conditions.items.push(
            new EventItem(
              this,
              this.objAlert.conditions.items.length,
              null,
              this.eventsTab.optionSelected.id,
              this.eventsTab.optionSelected.icon || '',
              this.eventsTab.optionSelected.description,
              unsupportedInfo,
              this.eventsTab.optionSelected.typeId,
              this.eventsTab.optionSelected.tdeId,
              this.eventsTab.optionSelected.isSimultaneous
                ? this.objAlert.conditions.simultaneous
                : false,
              this.eventsTab.optionSelected.complementId,
              this.eventsTab.optionSelected.complementDescription,
              this.eventsTab.optionSelected.typeId == 4 ? value : null,
              geofence,
              this.eventsTab.optionSelected.tooltip,
              this.eventsTab.optionSelected.hasGeoFences,
              this.eventsTab.optionSelected.isSimultaneous,
            ),
          );
        }

        if (this.objAlert.conditions.items.every(item => item.isSimultaneous)) {
          this.alertCanBeSimultaneous = true;
        } else {
          this.alertCanBeSimultaneous = false;
          this.objAlert.conditions.simultaneous = false;
        }

        if (this.$.querySelector('#popup-add-required-info').hasAttribute('open'))
          this.$.querySelector('#popup-add-required-info').toggle();
        this.$timeout(() => {
          this.eventsTab.options
            .filter(option => option.id == this.eventsTab.optionSelected.id)
            .map(item => {
              item.selected = false;

              return item;
            });
          this.eventsTab.optionSelected = null;
        }, 300);
      },
      () => {
        this.bodyBlocked = false;
      },
    );
  }

  // Eventos da aba destinatários
  evtClickAddRecipient() {
    // Apenas adiciona quem não está na lista
    this.recipientsTab.optionSelected.forEach(itemSel => {
      const item = this.objAlert.recipients.filter(
        recip => recip.item.id == itemSel.originalObj.id,
      )[0];

      if (!item) {
        this.objAlert.recipients.push(
          new RecipientsItem(this.objAlert.recipients.length, itemSel.originalObj),
        );
      }
    });

    this.$timeout(() => {
      this.recipientsTab.options.forEach(opt => {
        opt.selected = false;

        return opt;
      });
      this.recipientsTab.optionSelected = [];
    }, 300);
  }

  // Eventos do popup de opção da Grid de condições
  evtChangeInputConditionSliderValue(value) {
    this.conditionSliderObj.set(value);
  }

  // Eventos do popup para limpar a lista de condições
  evtClickConfirmClearConditions(param) {
    if (param) {
      this.objAlert.conditions.items = [];

      this.dropdownOptionsAlert.forEach(item => {
        if (item.slot == 'applyOn') {
          item.btnCheckAll = false;
        }
      });
    } else {
      this.dropdownOptionsAlert[0].options.forEach((item, idx) => {
        item.selected = this.dropdownOnReturnItem.value == item.value;
        if (item.selected) this.dropdownOptionsAlert[0].optSelected = idx;
      });
      this.dropdownOptionsAlert[0].optionSelected = this.dropdownOnReturnItem;
    }

    this.$.querySelector('#popup-confirm-clear-conditions').toggle();
  }

  // Evento para limpar campo de data
  evtClickCancelDateEnd(option) {
    option.dateObj.clear();
    option.showCancel = false;
    this.objAlert[option.field] = null;
  }

  evtClickBtnPowerPopup(type, popupId, dataset, option) {
    if (type == 'close') {
      switch (popupId) {
        case 'popup-unsupported-info':
          this.$.querySelector('#popup-unsupported-info').toggle();
          break;
        case 'popup-condition-option-selected':
          this.$.querySelector('#popup-condition-option-selected').toggle();
          break;
        case 'popup-period-picker':
          this.$.querySelector('#popup-period-picker').toggle();
          break;
        case 'popup-validation-messages':
          this.$.querySelector('#popup-validation-messages').toggle();
          break;
        case 'popup-conditions': {
          this.eventsTab.options.forEach(opt => {
            opt.selected = false;
          });
          this.eventsTab.optionSelected = null;

          this.$.querySelector('#popup-conditions').toggle();
          break;
        }
        case 'popup-recipients': {
          this.recipientsTab.options.forEach(opt => {
            opt.selected = false;
          });
          this.recipientsTab.optionSelected = [];

          this.$.querySelector('#popup-recipients').toggle();
          break;
        }
        default:
          break;
      }
    } else if (type == 'open') {
      switch (popupId) {
        case 'popup-unsupported-info': {
          this.popupUnsupportedInfo = Object.clone([], dataset);
          this.$.querySelector('#popup-unsupported-info').toggle();
          break;
        }
        case 'popup-condition-option-selected': {
          const item = { ...dataset };
          this.conditionSelected = { item, option };

          const getSelectedOption = complementId => {
            let selectedTab;

            switch (complementId) {
              case 1:
                selectedTab = 'speed';
                break;

              case 2:
                selectedTab = 'rpm';
                break;

              case 11:
                selectedTab = 'days';
                break;

              default:
                break;
            }

            return selectedTab;
          };

          if (option == 'value') {
            this.eventsTab.valueSelected = getSelectedOption(item.complementId);

            this.conditionSliderObj.updateOptions({
              range: {
                min: this.eventsTab.valuesConfig[this.eventsTab.valueSelected].min,
                max: this.eventsTab.valuesConfig[this.eventsTab.valueSelected].max,
              },
              start:
                this.conditionSelected.item.value ||
                this.eventsTab.valuesConfig[this.eventsTab.valueSelected].min,
            });
            this.conditionSliderObj.set(this.conditionSelected.item.value);
          }

          this.$.querySelector('#popup-condition-option-selected').toggle();
          break;
        }
        case 'popup-period-picker': {
          this.$.querySelector('#popup-period-picker').toggle();
          break;
        }
        case 'popup-conditions': {
          this.$.querySelector('#popup-conditions').toggle();
          break;
        }
        case 'popup-recipients': {
          this.$.querySelector('#popup-recipients').toggle();
          break;
        }
        default:
          break;
      }
    } else if (type == 'primaryBtn') {
      switch (popupId) {
        case 'popup-condition-option-selected': {
          if (this.conditionSelected.option == 'geofence') {
            const geofenceSelected = Object.clone([], this.conditionSelected.item.geofence);
            this.objAlert.conditions.items
              .filter(item => item.index == this.conditionSelected.item.index)
              .map(item => {
                item.geofence = geofenceSelected;

                return item;
              });
          } else if (this.conditionSelected.option == 'value') {
            const { value } = this.conditionSelected.item;
            this.objAlert.conditions.items
              .filter(item => item.index == this.conditionSelected.item.index)
              .map(item => {
                item.value = value;

                return item;
              });
          }

          this.$.querySelector('#popup-condition-option-selected').toggle();
          this.$timeout(() => {
            this.conditionSelected = null;
          }, 300);
          break;
        }
        case 'popup-conditions': {
          this.evtClickAddEvent();
          if (this.$.querySelector('#popup-conditions').hasAttribute('open'))
            this.$.querySelector('#popup-conditions').toggle();
          break;
        }
        case 'popup-recipients': {
          this.evtClickAddRecipient();
          if (this.$.querySelector('#popup-recipients').hasAttribute('open'))
            this.$.querySelector('#popup-recipients').toggle();
          break;
        }
        default:
          break;
      }
    } else if (type == 'item') {
      switch (popupId) {
        case 'popup-conditions': {
          this.eventsTab.options.forEach(opt => {
            opt.selected = false;
          });

          dataset.selected = true;
          this.eventsTab.optionSelected = dataset;

          break;
        }
        case 'popup-recipients': {
          dataset.selected = !dataset.selected;
          this.recipientsTab.optionSelected = this.recipientsTab.options.filter(
            // eslint-disable-next-line no-shadow
            option => option.selected,
          );
          break;
        }
        default:
          break;
      }
    }
  }

  changePage(page) {
    this.$scope.$broadcast('changePage', {
      page,
      payload: {
        filter: {
          conditions: this.stateConfig.filterConditions,
        },
        isPaginated: this.stateConfig.gridConfig.backPagination,
      },
    });
  }

  /* Observers */
  __simultaneousTippyObserver() {
    const simultaneousElem = this.$.querySelector('#checkbox-simultaneous');

    if (simultaneousElem) {
      if (!this.alertCanBeSimultaneous) {
        this.simultaneousTippyTitle =
          '"Veículo sem posição" não permite que este alerta funcione simultaneamente com as outras condições.';
      } else {
        this.simultaneousTippyTitle = '';
      }
    }
  }

  __periodPickerValueChanged({ detail }) {
    if (!this.alertPeriodConfig || !this.alertPeriodConfig.options) return;

    const { value } = detail;

    this.alertPeriodConfig.options.forEach(item => {
      item.selected =
        (Array.isArray(item.value) ? item.value : [item.value]).join() === value.join();
    });

    if (!this.alertPeriodConfig.options.find(item => item.selected)) {
      this.alertPeriodConfig.options
        .filter(item => item.value === null)
        .forEach(item => {
          item.selected = true;
        });
    }
  }
}

export { PowerReportAlertsController };
