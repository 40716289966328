/* eslint new-cap: ["error", { "newIsCap": false }] */
import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-period/power-period';
import '@power/power-components/components/power-radio-button-group/power-radio-button-group';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/directives/ng-tippy/ng-tippy';
import '@power/power-components/directives/infinite-scroll/infinite-scroll';
import { GolfleetReportAlertsController } from '../golfleet-report-alerts/golfleet-report-alerts';

import '@power/power-components/components/power-card-alert/power-card-alert';
import '@power/power-components/components/power-custom-checkbox-list/power-custom-checkbox-list';
import '@power/power-components/components/power-simple-card-list/power-simple-card-list';

import template from './golfleet-flex-report-alerts.html';
import './golfleet-flex-report-alerts.scss';

class GolfleetFlexReportAlertsController extends GolfleetReportAlertsController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
      '$sce',
      '$rootScope',
    ];
  }

  /**
   * Creates an instance of ReportAlertsController.
   * @memberof ReportAlertsController
   */
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
    $sce,
    $rootScope,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
      $sce,
      $rootScope,
    );

    Object.assign(this, { $sce, $rootScope });

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        isSingleSignon: behavior.session.isSingleSignon,
        session: behavior.session,
      }),
    )(this);

    this.dropdownOptionsAlert = [
      {
        id: 1,
        type: 'Radio',
        title: 'Aplicar alerta em',
        titleDescription: 'Aplicar alerta em',
        icons: {
          before: 'directions_car',
          after: null,
        },
        placeholder: 'Veículos ou condutores',
        enabled: true,
        loading: true,
        method: 'Filter/GetDefaultFilter',
        methodAdm: 'FilterAdm/GetDefaultFilter',
        methodParams: {
          id: 0,
        },
        slot: 'applyOnType',
        childId: 2,
        optSelected: 0,
        optionSelected: {
          id: 0,
          description: 'Veículos',
          key: 'vehicles',
          value: 0,
          iconBefore: 'directions_car',
          selected: true,
          options: [],
        },
        options: [
          {
            id: 0,
            description: 'Veículos',
            key: 'vehicles',
            value: 0,
            iconBefore: 'directions_car',
            selected: true,
            options: [],
          },
          {
            id: 1,
            description: 'Condutores',
            key: 'drivers',
            value: 1,
            iconBefore: 'airline_seat_recline_normal',
            selected: false,
            options: [],
          },
          {
            id: 2,
            description: 'Grupos',
            key: 'groups',
            value: 2,
            iconBefore: 'group_work',
            selected: false,
            options: [],
          },
        ],
      },
      {
        id: 2,
        type: 'Custom',
        title: null,
        icons: {
          isEnable: false,
          before: null,
          after: null,
        },
        placeholder: '',
        enabled: true,
        filteredOptions: [],
        optionSelected: null,
        options: [],
        checkAll: true,
        btnCheckAll: false,
        slot: 'applyOn',
        parentId: 1,
      },
      {
        id: 3,
        type: 'Disclaimer',
        icons: {
          isEnable: false,
          before: null,
          after: null,
        },
      },
      {
        id: 4,
        type: 'Custom',
        slot: 'periodPreset',
        title: 'Filtro de período',
        titleDescription: 'Filtro de período',
        icons: {
          isEnable: false,
          before: null,
          after: null,
        },
        placeholder: '',
        enabled: true,
        childId: 4,
      },
    ];

    this.driverIdentificationTypes = [
      {
        icon: 'insert_link',
        title: 'Identificação por Software',
        description:
          'Identifica automaticamente a viagem com o condutor que esteja vinculado ao veículo e todos os eventos da viagem (inclusive alertas em tempo real) são também automaticamente vinculados ao condutor.',
      },
      {
        icon: 'gs_rfid',
        title: 'Identificação por Acessório',
        description:
          'Realiza o reconhecimento do condutor mediante o uso do cartão RFID/iButton, vinculando-o à viagem e em todos os incidentes a ela relacionados, como infrações, além de alertas em tempo real após a identificação, tais como excesso de velocidade, entrada/saída de áreas de controle, entre outros.',
      },
    ];
  }

  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();
  }

  // eslint-disable-next-line no-unused-vars
  _loadTabConditions(popupDataset) {
    if (popupDataset) {
      this._loadSystemAlerts(popupDataset);
    }
  }
}

class GolfleetFlexReportAlerts {
  /**
   * Creates an instance of ReportAlerts.
   * @memberof ReportAlerts
   */
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetFlexReportAlertsController;
  }
}

angular
  .module('golfleet-flex-report-alerts', [
    'ngRedux',
    'infinite-scroll',
    'ng-tippy',
    'power-fab',
    'power-toolbar',
    'power-dropdown',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-radio-button-group',
    'power-toast',
    'power-simple-card-list',
    'power-card-alert',
    'power-custom-checkbox-list',
  ])
  .component('golfleetFlexReportAlerts', new GolfleetFlexReportAlerts());
