/* eslint-disable camelcase */
import Form_FleetPolicyAdm_Trip from './default/Form_FleetPolicyAdm_Trip.json';
import Form_TcoAccident from './default/Form_TcoAccident.json';
import Form_Driver_Trip from './default/Form_Driver_Trip.json';
import Form_Announcement from './default/Form_Announcement.json';
import Form_OrderService from './default/Form_OrderService.json';
import Form_Checklist from './default/Form_Checklist.json';
// Tracking
import Form_FleetPolicyAdm_Tracking from './tracking/Form_FleetPolicyAdm_Tracking.json';
import Form_Driver_Tracking from './tracking/Form_Driver_Tracking.json';
// Golfleet Flex
import Form_FleetPolicyAdm_Flex from './flex/Form_FleetPolicyAdm_Flex.json';

export default {
  Form_FleetPolicyAdm_Trip,
  Form_TcoAccident,
  Form_Driver_Trip,
  Form_Announcement,
  Form_OrderService,
  Form_Checklist,
  // Tracking
  Form_FleetPolicyAdm_Tracking,
  Form_Driver_Tracking,
  // Golfleet Flex
  Form_FleetPolicyAdm_Flex,
};
